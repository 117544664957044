<template>
  <div>
    <div class="modal-header pr-4 pl-4">
      <h5 class="modal-title">การยืนยันตัวตน</h5>
    </div>
    <div class="modal-body pr-4 pl-4">
      <div class="d-flex justify-content-between">
        <div class="col-auto p-0">
          เราได้ส่งรหัสยืนยันไปที่โทรศัพท์หมายเลข
        </div>
        <div class="col text-center p-0 tell">{{ user.phone }}</div>
      </div>
      <div>รหัสนี้จะหมดภายใน 3 นาที</div>
      <div class="d-flex justify-content-between mt-4">
        <AuthOtpInput
          class="otp-digit"
          type="tel"
          maxlength="1"
          v-for="input in otpInput"
          :key="input.id"
          :id="input.id"
          @keydown="next($event, input.prev, input.next, input.id)"
        />
      </div>
      <AuthButton
        theme="dark"
        type="full"
        bg="#e21e1e"
        class="mt-5"
        @click="checkOtp"
        >ยืนยัน</AuthButton
      >
      <div class="mt-4">ยังไม่ได้รหัสยืนยัน?</div>
      <div>
        <AuthLink href="">ส่งรหัสอีกครั้ง</AuthLink>
      </div>
      <div>
        <AuthLink href="">ใช้หมายเลขอื่น</AuthLink>
      </div>
    </div>
  </div>
</template>

<script>
import { AuthElement } from "../styled";
import { AwsOtp } from "../../services";
export default {
  components: { ...AuthElement },
  props: {
    user: Object
  },
  computed: {
    otpInput() {
      let input = [];
      for (let index = 1; index <= 6; index++) {
        input.push({
          id: `otp-${index}`,
          prev: `#otp-${index > 1 ? index - 1 : index}`,
          next: `#otp-${index < 6 ? index + 1 : index}`
        });
      }
      return input;
    }
  },
  emits: ["gotoUserInfo", "submitUser", "error", "loading", "done"],
  methods: {
    next(event, prev, next, id) {
      let current = document.getElementById(id);
      if (event.key != "Backspace") {
        setTimeout(() => {
          document.querySelector(next).focus();
        }, 100);
      } else if (event.key == "Backspace") {
        event.preventDefault();
        if (current.value != "") {
          current.value = "";
        } else {
          document.querySelector(prev).focus();
        }
      }
    },
    show() {
      this.$refs.modal.show();
    },
    async checkOtp() {
      //TODO Check OTP API Here
      this.$emit("loading");
      let digit = "";
      document.querySelectorAll(".otp-digit").forEach(item => {
        digit += item.value;
      });
      const aws_otp = new AwsOtp(this.user.phone, this.user.email);
      const confirm = await aws_otp.auth(digit);
      if (confirm) {
        setTimeout(() => {
          this.$emit("submitUser", {
            otp_verified: true
          });
          this.$emit("gotoUserInfo");
        }, 100);
      } else {
        this.$emit("error", "รหัส OTP ไม่ถูกต้อง");
      }
      this.$emit("done");
    }
  }
};
</script>

<style scoped>
.tell {
  color: #e21e1e;
}
</style>
