import { facebook_app_id } from "../config";

export class Facebook {
  async connect() {
    await this.#getScript();
  }
  #getScript = () =>
    new Promise(resolve => {
      if (window.FB) {
        resolve(window.FB);
      } else {
        (function(d, s, id) {
          var js,
            fjs = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) {
            return;
          }
          js = d.createElement(s);
          js.id = id;
          js.src = "https://connect.facebook.net/en_US/sdk.js";
          fjs.parentNode.insertBefore(js, fjs);
          js.addEventListener("load", () => {
            window.FB.init({
              appId: facebook_app_id,
              cookie: true,
              xfbml: true,
              version: "v13.0"
            });

            window.FB.AppEvents.logPageView();
            resolve(window.FB);
          });
        })(document, "script", "facebook-jssdk");
      }
    });

  getLoginStatus() {
    return new Promise(resolve => {
      window.FB.getLoginStatus(res => {
        resolve(res);
      });
    });
  }

  login() {
    return new Promise(resolve => {
      window.FB.login(
        res => {
          resolve(res);
        },
        { scope: "email,public_profile", return_scopes: true }
      );
    });
  }

  getInfo(id) {
    return new Promise(resolve => {
      window.FB.api(
        `/${id}/?fields=id,name,email,picture,first_name,last_name`,
        res => {
          resolve(res);
        }
      );
    });
  }
}
