<template>
  <div
    class="modal fade"
    id="ChangeLangModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">ภาษาแนะนำ</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="lang active mb-0" lang="th" @click="selectLang('th')">
            <img class="flag-icon" :src="langs.th.flag" alt="" />
            <div class="name-section ">
              <p class="username">{{ langs.th.lang }}</p>
            </div>
          </div>
        </div>
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">ภาษาทั้งหมด</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div
            class="lang"
            :lang="lang.key"
            @click="selectLang(lang.key)"
            v-for="lang in langs"
            :key="lang.key"
          >
            <img class="flag-icon" :src="lang.flag" alt="flag" />
            <div class="name-section">
              <p class="username">{{ lang.lang }}</p>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            ยกเลิก
          </button>
          <button type="button" class="btn btn-primary">บันทึก</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import thaiFlagIcon from "../../../../assets/icons/thailand.svg";
import engFlagIcon from "../../../../assets/icons/uk.svg";
import chineseFlagIcon from "../../../../assets/icons/china.svg";
export default {
  data: () => {
    return {
      langs: {
        th: {
          key: "th",
          lang: "ภาษาไทย",
          flag: thaiFlagIcon
        },
        en: {
          key: "en",
          lang: "English",
          flag: engFlagIcon
        },
        cn: {
          key: "cn",
          lang: "中文",
          flag: chineseFlagIcon
        }
      }
    };
  },
  methods: {
    selectLang: lang => {
      const active = document.querySelectorAll(".lang");
      const langElem = document.querySelector(`.lang[lang='${lang}']`);

      active.forEach(item => {
        item.classList.remove("active");
      });

      langElem.classList.add("active");

      console.log(lang);
    }
  }
};
</script>

<style scoped>
.modal-content {
  background-color: #f9f9f9 !important;
  width: auto;
  display: flex;
}

.modal-header {
  border: none !important;
  background-color: #f9f9f9;
  width: 90%;
  margin: 0rem auto;
}
.modal-body {
  background-color: #fff !important;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 90%;
  margin: 1rem auto;
  flex-wrap: wrap;
}

.modal-footer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: none !important;
  background-color: #f9f9f9;
}

.user-input {
  width: 100%;
}

.btn-secondary {
  background-color: transparent !important;
  border: 0px !important;
  color: #e21e1e;
  padding: 0.5rem 2rem;
}

.btn-primary {
  border-radius: 0.2rem;
  background-color: #e21e1e;
  border: 1px solid transparent;
  padding: 0.5rem 2rem;
}

.close {
  font-size: 2rem;
  font-weight: 400;
}

.lang {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  padding: 1rem;
  cursor: pointer;
}

.lang img {
  width: 40px;
  height: 30px;
  margin-right: 1rem;
}
p {
  margin: 0 !important;
}
.lang .name-section {
  display: flex;
  justify-content: center;
  align-items: center;
}

.lang.active {
  background-color: #5393f981;
}
</style>
