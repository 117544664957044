<template>
  <div>
    <div class="pt-3">
      <Icon
        class="m-auto"
        size="5rem"
        bdColor="var(--success)"
        bdWidth="4px"
        radius="50%"
      >
        <i class="fas fa-check text-success"></i>
      </Icon>
      <h4 class="text-center mt-5 mb-5">สมัครเสร็จเรียบร้อยแล้ว</h4>
    </div>
    <AuthButton @click="finish" theme="dark" type="full" bg="#e21e1e"
      >ตกลง</AuthButton
    >
  </div>
</template>

<script>
import { CommonElement, AuthElement } from "../styled";
export default {
  components: { ...CommonElement, ...AuthElement },
  methods: {
    finish() {
      location.reload();
    }
  }
};
</script>

<style></style>
