import HttpRequest from "./http_request";
import { api_url } from "../config";
const prefix = "/portal";

class PortalService extends HttpRequest {
  constructor() {
    super(api_url);
  }

  async getAllSports() {
    const { data } = await this.get(`${prefix}/allsports`);
    return data;
  }

  async get_refno(product) {
    const { data } = await this.get(`payment/refno/${product}`);
    return data;
  }
}

export default PortalService;
