import SportProvider from "@/resources/sportProvider_provider";

const sportService = new SportProvider();

const state = {
  recommendedPlaces: [],
  cheapestPrice: []
};

const getters = {
  recommendedPlaces: state => state.recommendedPlaces
};

const actions = {
  async getRecommendedPlaces({ commit }, payload) {
    let response;

    try {
      response = await sportService.getRecommendedPlaces(payload);
    } catch (error) {
      // Handle error
      console.log(error);
      return;
    }

    // Handle success
    commit("SET_RECOMMENDED_PLACES", response);
  }
};

const mutations = {
  SET_RECOMMENDED_PLACES(state, payload) {
    state.recommendedPlaces = payload;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
