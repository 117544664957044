<template>
  <div>
    <div class="modal-header  pl-4 pr-4">
      <h5 class="modal-title">ใกล้เสร็จแล้ว !</h5>
    </div>
    <div class="modal-body pl-4 pr-4">
      <div class="pt-3" v-if="mode == 'PhoneRegister'">
        <div class="form-group">
          <label>อีเมลของท่าน</label>
          <AuthInput
            v-model="email"
            type="email"
            placeholder="example@mail.com"
          />
        </div>
        <div class="form-group">
          <label>ชื่อผู้ใช้</label>
          <AuthInput
            v-model="user_name"
            type="text"
            placeholder="ตั้งชื่อผู้ใช้ของท่าน"
          />
        </div>
        <div class="form-group">
          <label>ชื่อ</label>
          <AuthInput
            v-model="first_name"
            type="text"
            placeholder="ระบุชื่อของท่าน"
          />
        </div>
        <div class="form-group">
          <label>นามสกุล</label>
          <AuthInput
            v-model="last_name"
            type="text"
            placeholder="ระบุนามสกุลของท่าน"
          />
        </div>
        <div class="form-group">
          <label>รหัสผ่าน</label>
          <AuthInput :type="showPassword" v-model="password" />
          <small class="text-warning" v-if="checkStrength()"
            >รหัสไม่ผ่านความต้องการขั้นต่ำ</small
          >
          <div class="d-flex justify-content-between">
            <small
              >รหัสผ่านควรมีจำนวนตัวอักษร 8-12 ตัว <br />
              และควรมีทั้งตัวเลขและตัวอักษร</small
            >
            <label for="show-password">
              <AuthCheckbox
                type="checkbox"
                @change="togglePasswordVisibility"
              />
              <small>แสดงรหัสผ่าน</small>
            </label>
          </div>
        </div>
      </div>
      <div class="pt-3" v-else>
        <div class="form-group">
          <label>ชื่อผู้ใช้</label>
          <AuthInput
            v-model="user_name"
            type="text"
            placeholder="ตั้งชื่อผู้ใช้ของท่าน"
          />
        </div>
      </div>
      <div>
        <AuthButton @click="finish" theme="dark" type="full" bg="#e21e1e"
          >สร้างบัญชีผู้ใช้</AuthButton
        >
      </div>
    </div>
  </div>
</template>

<script>
import { AuthElement, CommonElement } from "../styled";
import { passwordStrength } from "check-password-strength";
import UserProvider from "../../resources/user_provider";
export default {
  components: { ...AuthElement, ...CommonElement },
  props: {
    mode: String
  },
  data() {
    return {
      email: null,
      password: null,
      passwordValidate: "",
      user_name: null,
      first_name: null,
      last_name: null,
      error: false,
      showPassword: "password",
      existEmail: false,
      existUsername: false
    };
  },
  emits: ["submitUser", "finish", "error"],
  methods: {
    togglePasswordVisibility() {
      if (this.showPassword == "password") {
        this.showPassword = "text";
      } else {
        this.showPassword = "password";
      }
    },
    async finish() {
      if (this.checkInput()) {
        await this.checkExist();
        if (this.existEmail || this.existUsername) {
          let msg = "มี ";
          if (this.existUsername) msg += "ชื่อผู้ใช้ ";
          if (this.existEmail) msg += "อีเมล ";
          msg += "ในระบบอยู่แล้ว";
          this.$emit("error", msg);
        } else {
          const user_extends =
            this.mode == "PhoneRegister"
              ? {
                  email: this.email,
                  password: this.password,
                  username: this.user_name,
                  firstname: this.first_name,
                  lastname: this.last_name
                }
              : { username: this.user_name };
          this.$emit("submitUser", user_extends, false);
          this.$emit("finish");
        }
      } else {
        this.$emit("error", "กรุณากรอกข้อมูลให้ครบ");
      }
    },
    async checkExist() {
      const user_provider = new UserProvider();
      if (this.mode == "PhoneRegister") {
        this.existEmail = await user_provider.checkExist(this.email, "email");
      }
      this.existUsername = await user_provider.checkExist(
        this.user_name,
        "username"
      );
    },
    checkInput() {
      let check = false;
      if (this.mode == "PhoneRegister") {
        if (
          this.password &&
          this.first_name &&
          this.last_name &&
          this.user_name &&
          !this.checkStrength()
        ) {
          check = true;
        }
      } else {
        if (this.user_name) {
          check = true;
        }
      }
      return check;
    },
    checkStrength() {
      return this.passwordValidate == "Too weak" ? true : false;
    }
  },
  watch: {
    password(input) {
      this.passwordValidate = passwordStrength(input).value;
    }
  }
};
</script>

<style></style>
