import HttpRequest from "./http_request";
import { api_url } from "../config";
export default class HistoryProvider extends HttpRequest {
  constructor() {
    super(api_url);
  }
  async getMyBooked() {
    const auth = {
      Authorization: "Bearer " + localStorage.getItem("user_token")
    };
    this.setHeader(auth);
    const { data } = await this.get(`/my-booked`);
    return data;
  }
  async getHistory(id) {
    const auth = {
      Authorization: "Bearer " + localStorage.getItem("user_token")
    };
    this.setHeader(auth);
    const { data } = await this.get(`/portal/getHistory/${id}`);
    return data;
  }
}
