<template>
  <div>
    <div class="form-group">
      <label>หมายเลขโทรศัพท์ของท่าน</label>
      <AuthPhoneInputWrapper class="login-class" style="padding: 0;border-radius: 5px">
        <AuthCountryPhoneFront style="background-color: white;border: 2px solid #f2f2f2;border-radius: 5px 0 0 5px;">
          <img src="https://purecatamphetamine.github.io/country-flag-icons/3x2/TH.svg" />+66
        </AuthCountryPhoneFront>
        <AuthPhoneInput v-model="phone_number" type="tel" />
      </AuthPhoneInputWrapper>
    </div>
    <AuthButton
      theme="dark"
      type="full"
      bg="#e21e1e"
      class="mt-5"
      :class="accept ? '' : 'disabled'"
      @click="submitUser"
      >ยืนยัน OTP</AuthButton
    >
    <div class="d-flex" style="align-items: center">
      <input id="tcpv2" class="auth-checkbox" v-model="accept" type="checkbox" style="border-radius: 30px;margin: 9px;height: 9px;width: 9px;" />
      <label style="margin: 0" for="tcpv2">
        <small>
          ท่านยอมรับ <AuthLink href="/terms" target="_blank">ข้อกำหนดการใช้งาน</AuthLink> และ
          <AuthLink href="/privacy-policy" target="_blank">นโยบายความเป็นส่วนตัว</AuthLink> ของ แมทช์เดย์
        </small>
      </label>
    </div>
  </div>
</template>

<script>
import UserProvider from "../../resources/user_provider";
import { AuthElement, CommonElement } from "../styled";
export default {
  components: { ...AuthElement, ...CommonElement },
  data() {
    return {
      accept: false,
      phone_number: "",
      existPhone: false
    };
  },
  emits: ["submitUser"],
  methods: {
    async checkExist() {
      const user_provider = new UserProvider();
      this.$parent.$emit("loading");
      this.existPhone = await user_provider.checkExist(
        this.phone_number,
        "phone"
      );
      this.$parent.$emit("done");
    },
    async submitUser() {
      await this.checkExist();
      if (this.existPhone) {
        this.$parent.$emit("error", "มีหมายเลขนี้ในระบบอยู่แล้ว");
      } else {
        this.$emit("submitUser", {
          phone: this.phone_number,
          policy_accept: this.accept
        });
      }
    }
  }
};
</script>

<style scoped>
 .login-class {
    padding: 0.5rem;
    background-color: #f7f4f4;
    box-shadow: 0px 1px 1px #00000029;
    border: none;
    border-radius: 5px;
  }
</style>
