<style lang="scss" scoped>
.my-title {
  font-family: 'Prompt';
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  line-height: 67px;
  color: #FFFFFF;
}

.my-title-mobile {
  font-family: 'Prompt';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 67px;
  color: #FFFFFF;
}


.fit-image {
  width: 100%;
  object-fit: cover;
  height: 100%;
  /* only if you want fixed height */
}

.my-title2 {
  font-family: 'Prompt';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #FFFFFF;
}

.my-title2-mobile {
  font-family: 'Prompt';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 36px;
  color: #FFFFFF;
}

.my-sub-title {
  width: 512px;
  text-align: justify;
  font-family: 'Prompt';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  color: #FFFFFF;
}

.my-sub-title-mobile {
  font-family: 'Prompt';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #FFFFFF;
}

.round-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 13px 16px;
  width: 150px;
  height: 55px;
  border: solid 1px #E13122;
  background: #E13122;
  border-radius: 57px;

}

.round-btn-txt {
  font-family: 'Prompt';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;

  color: #FFFFFF;

}

.flex-container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
}

.flex-container>div {
  text-align: center;
  align-self: center;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
}

.files-container {
  display: flex;
}

.files-container .col-main {
  flex: 1 1 100%;
  overflow-x: scroll;
}

.slider-files-container {
  display: inline-flex;
}

.slider-content {
  margin: 10px;
  display: flex;
  flex-direction: column;
  width: 200px;
  align-items: center;
  align-self: center;
}
</style>

<template >
  <div class="row no-gutters justify-content-center" style="background:#E13122">
    <div class="col-xs-12  col-sm-12 col-md-6 col-lg-6" :align="!isMobile() ? '' : 'center'"
      :style="!isMobile() ? 'padding-left:80px;margin-top:87px' : 'margin-top:24px'">
      <div v-if="!isMobile()">
        <div class="my-title mb-2">Welcome to matchday</div>
        <div class="my-sub-title">แพลตฟอร์มจองสนามกีฬา ที่รวบรวมสนามกีฬา<br>ไว้มากที่สุดสำหรับคนรักกีฬา
          ที่รวบรวมทุกกิจกรรม กีฬามาไว้ในมือของคุณ โหลดไว้ มีที่เล่นกีฬาแน่นอน</div>
        <div class="my-title2 mt-5">Find us at</div>
        <div style="display:flex" class="mt-2">
          <img src="@/assets/appstore.png" width="157" height="52" class="mr-2" @click="apple()">
          <img src="@/assets/playstore.png" width="177" height="52" class="ml-2" @click="gg()">
        </div>
        <div class="my-title2 mt-3">or scan to download</div>
        <img src="@/assets/Matchday.jpg" width="157" height="157" class="mt-3">
      </div>
      <div v-else>
        <div class="my-title-mobile mb-2">Welcome to matchday</div>
        <div class="my-sub-title-mobile px-2">แพลตฟอร์มจองสนามกีฬา ที่รวบรวมสนามกีฬา<br>ไว้มากที่สุดสำหรับคนรักกีฬา
          ที่รวบรวมทุกกิจกรรม กีฬามาไว้ในมือของคุณ โหลดไว้ มีที่เล่นกีฬาแน่นอน</div>
        <div class="my-title2-mobile mt-2">Find us at</div>
        <div style="display:flex;justify-content: center;" class="mt-2">
          <img src="@/assets/appstore.png" width="175" height="50">
        </div>
        <div style="display:flex;justify-content: center;" class="mt-2">
          <img src="@/assets/playstore.png" width="175" height="50">
        </div>
      </div>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 p-0 m-0" style="padding-left:80px;margin-top:87px">
      <div>
        <img src="@/assets/newbg.png" class="fit-image">
      </div>
    </div>
    <div class="col-xs-12  col-sm-12 col-md-12 col-lg-12 text-center py-4" style="">

      <div class="my-title mb-n2" style="color:white;font-size:32px;font-weight: 500;">สื่อที่พูดถึงเรา</div>
      <!-- <div class="flex-container">
        <div><img src="@/assets/brandinside.png" width="170px"></div>
        <div><img src="@/assets/techsauce.png" width="120px"></div>
        <div><img src="@/assets/smestartup.png" width="140px"></div>
      </div> -->
      <div class="files-container">
        <div class="col-main">
          <div class="slider-files-container">
            <div class="slider-content">
              <img src="@/assets/brandinside.png" @click="braninside" style="width:170px">
            </div>
            <div class="slider-content ">
              <img src="@/assets/techsauce.png" @click="techsource" style="width:120px">
            </div>
            <div class="slider-content ">
              <img src="@/assets/smestartup.png" @click="smestart" style="width:140px">
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="col-xs-12  col-sm-12 col-md-12 col-lg-12 " :align="'center'"
      :style="!isMobile() ? 'padding-left:3%;padding-right:3%;background-color: white;' : 'padding:3%;background-color: white;'">
      <div v-if="!isMobile()" class="my-5" @click="$router.push({ path: 'partners', query: { trial:'1' }})" style="cursor: pointer;">
        <img src="@/assets/banner1.png" class="fit-image" style="width:80%">
      </div>
      <div v-else style="height:9vh" @click="$router.push('/partners')">
        <img src="@/assets/banner1.png" class="fit-image" style="width:100%">
      </div>
    </div>
    <div
      :class="isMobile() ? 'col-xs-12 col-sm-12 col-md-6 col-lg-6 text-center' : 'col-xs-12 col-sm-12 col-md-6 col-lg-6 text-right'"
      :style="isMobile() ? 'background-color: white;' : 'padding-left:80px;padding-top:50px;padding-bottom:50px;background-color: white;'">
      <div>
        <img src="@/assets/new_section.png" class="fit-image" :style="isMobile() ? 'width:80%' : 'width:45%'">
      </div>
    </div>
    <div class="col-xs-12  col-sm-12 col-md-6 col-lg-6 " :align="!isMobile() ? '' : 'center'"
      :style="!isMobile() ? 'padding-left:80px;background-color: white;padding-top:50px' : 'background-color: white;'">
      <div v-if="!isMobile()" class="my-5">
        <div class="my-title mb-n2 mt-5" style="color:black">SLOT BOOKING</div>
        <div class="my-sub-title" style="color:#E13122;font-weight: 500;">จองได้ตลอด การันตี 100%
        </div>
        <div class="my-sub-title" style="color:black">รวบรวมสนามกีฬามาให้คุณแล้ว อยากเล่นกีฬาไหนก็หาง่ายๆ แค่นึกถึง
          matchday
        </div>
        <div class="my-title2 mt-3">
          <button class="round-btn" @click="detail()">
            <span class="round-btn-txt">ดาวน์โหลด</span>
          </button>
        </div>
      </div>
      <div v-else style="height: 25vh;">
        <div class="my-title-mobile ml-4 text-left mb-n4" style="color:black">SLOT BOOKING</div>
        <div class="my-title-mobile ml-4 text-left mt-n5" style="color:white;font-weight: 400;font-size:20px">จองได้ตลอด
          การันตี 100%</div>
        <div class="my-title-mobile mx-4 text-left mt-n4"
          style="color:black;font-weight: 400;font-size:18px;line-height: 20px;">รวบรวมสนามกีฬามาให้คุณแล้ว
          อยากเล่นกีฬาไหนก็หาง่ายๆ แค่นึกถึง
          matchday
        </div>
        <div class="my-title2 mt-3 text-left ml-3">
          <button class="round-btn" @click="detail()">
            <span class="round-btn-txt">ดาวน์โหลด</span>
          </button>
        </div>
      </div>
    </div>
   

  </div>
</template>
<!-- <Search ref="search" :type="type" :location="current_location" /> -->
  <!-- <RecommendedPlace :recommendedPlace="recommendedPlace" />
  <RecommendedSport :recommendedSport="recommendedSport" />
  <div style="text-align: center;" id="google-ads"></div>
  <Review :reviews="reviews" /> -->
<script>
//import Search from "../components/Search/Search.vue";
// import RecommendedSport from "../components/RecommendedSport.vue";
// import RecommendedPlace from "../components/RecommendedPlace.vue";
// import Review from "../components/Review.vue";
// @ is an alias to /src

import { formatISO9075 } from "date-fns";

import { mapGetters, mapActions } from "vuex";
import { LocationService } from "../services";

export default {
  name: "Home",
  components: {
    //Search,
    // RecommendedSport,
    // RecommendedPlace,
    // Review
  },
  data() {
    return {
      type: "place",
      current_location: {
        allow: false,
        lat: 13.7241028,
        lng: 100.5281218
      },
    };
  },
  computed: {
    ...mapGetters({
      reviews: "sport/reviews",
      location: "location/location",
      recommendedSport: "sport/recommendedSports",
      recommendedPlace: "provider/recommendedPlaces",
      timeStart: "search/timeStart",
      timeEnd: "search/timeEnd"
    })
  },
  async beforeMount() {
    this.getReviews();
    this.getRecommendedSports();
    this.getLocation(); /* VueX Location */
    this.getCurrentLocation()
  },
  mounted() {
    // this.setTimeStart(
    //   formatISO9075(
    //     new Date(
    //       `${this.$refs.search.$refs.input.date} ${this.$refs.search.$refs.input.time_start}`
    //     )
    //   )
    // );
    // this.setTimeEnd(
    //   formatISO9075(
    //     new Date(
    //       `${this.$refs.search.$refs.input.date} ${this.$refs.search.$refs.input.time_end}`
    //     )
    //   )
    // );
    // this.init_google_ads()
  },
  methods: {
    braninside() {
      window.open('https://brandinside.asia/matchday/', '_blank')
    },
    techsource() {
      window.open('https://techsauce.co/pr-news/matchday-startup-sports-pointing-back-covid-19', '_blank')
    },
    smestart() {
      window.open('https://www.smethailandclub.com/startup-techstartup/8612.html', '_blank')
    },
    getMobileOperatingSystem() {
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;

      // Windows Phone must come first because its UA also contains "Android"
      if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
      }

      if (/android/i.test(userAgent)) {
        return "Android";
      }

      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
      }

      return "unknown";
    },

    gg() {
      
      window.open('https://play.google.com/store/apps/details?id=com.matchdayhub.application', '_blank');
    },
    apple() {
      window.open('https://apps.apple.com/th/app/matchday/id1504500902', '_blank');
    },
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    init_google_ads() {
      document.getElementById('google-ads').innerHTML = `<ins class="adsbygoogle"
        style="display:inline-block;width:728px;height:90px;background-color:#E13122"
        data-ad-client="ca-pub-8120700586595416"
        data-ad-slot="9086630564"></ins>`

      setTimeout(() => {
        (adsbygoogle = window.adsbygoogle || []).push({})
      }, 1000)
    },
    async getCurrentLocation() {
      try {
        const location = new LocationService()
        await location.accessLocation();
        this.current_location = location.getLocation()
        this.current_location.allow = true

      } catch (err) {
        console.log(err);
      }
    },
    ...mapActions({
      getReviews: "sport/getReviews",
      getLocation: "location/getLocation",
      getRecommendedSports: "sport/getRecommendedSports",
      setTimeStart: "search/setTimeStart",
      setTimeEnd: "search/setTimeEnd"
    }),
    changeType(value) {
      this.type = value;
    },
    detail() {
      const mtype = this.getMobileOperatingSystem()
      if (this.isMobile()) {
        if (mtype === 'iOS') {
          window.open('https://apps.apple.com/th/app/matchday/id1504500902', '_blank');

        } else {
          window.open('https://play.google.com/store/apps/details?id=com.matchdayhub.application', '_blank');
        }
      } else {
        window.scrollTo(0, 0);
      }
    }
  }
};
</script>
