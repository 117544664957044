<template>
  <footer class="footer text-light">
    <div class="d-flex justify-content-center">
      <div class="row pt-3 col">
        <!-- <div class="col-md">
          <img
            class="mb-3"
            src="@/assets/matchday-logo-2.png"
            width="120"
            alt=""
          />
          <p class="text-thin">
            {{ stringAsset("common/footer-message", "th") }}
          </p>
          <h5 class="text-thin">ดาวน์โหลดได้แล้ววันนี้ที่</h5>
          <div class="align-center">
            <a href="https://apps.apple.com/th/app/matchday/id1504500902">
              <img
                src="https://prod-media-as1.s3.ap-southeast-1.amazonaws.com/miscellaneous/Download_on_the_App_Store_Badge_TH_RGB_blk_092917_ikigei.svg"
                class="ap-icon"
                alt=""
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.matchdayhub.application"
            >
              <img
                src="https://prod-media-as1.s3.ap-southeast-1.amazonaws.com/miscellaneous/google-play-badge-th_zri8ru.png"
                class="gplay-icon"
                alt=""
              />
            </a>
          </div> 
        </div>-->
        <!-- <div class="col-md m-2">
           <h5>
            <router-link to="/about" title="เกี่ยวกับเรา" class="text-light"
              >เกี่ยวกับเรา</router-link
            >
          </h5>
          <h5 class="mb-5">
            <router-link to="/partners" title="เกี่ยวกับเรา" class="text-light"
              >เป็นพาร์ทเนอร์กับเราดีอย่างไร</router-link
            >
          </h5>
          <h5>Stay in touch</h5>
          <div class="form-group">
            <input
              type="text"
              class="form-control"
              :placeholder="stringAsset('common/email-placeholder', 'th')"
            />
          </div>
          <button style="background: #000" class="btn btn-dark text-light mb-3">
            Subscribe
          </button>
          <div>
            <a
              href="https://web.facebook.com/matchday.th"
              class="text-light pr-3 text-xl"
            >
              <i class="fab fa-facebook-f"></i>
            </a>
            <a href="https://twitter.com/matchdayth" class="text-light text-xl">
              <i class="fab fa-twitter"></i>
            </a>
          </div> 
        </div>-->
        <div class="col-md">
          <!-- <h5>ติดต่อเรา</h5> -->
          <div class="d-flex align-items-center" style="justify-content: center;">

            <div class="d-flex align-items-center" style="flex-wrap: wrap;width:650px;">
              <img src="@/assets/marker.png" class="icon mr-3" alt="" />
              <!-- <div>บริษัท แมทช์เดย์ ฮับ จำกัด เลขที่ 1 อาคารเอ็มไพร์ ทาวเวอร์ ชั้น 47 ยูนิต 4703 ถนนสาทรใต้ แขวงยานนาวา เขตสาทร กทม. 10120</div>
              <div class="break"></div> -->
              <div>
                Matchday Hub Co., Ltd. 1 empire tower, South Sathorn Road, Yan Nawa, Sathon, Bangkok
              </div>
            </div>
            <div class="d-flex align-items-center mr-5">
              <img src="@/assets/phone.png" class="icon mr-3" alt="" />
              <div>093-555-2935</div>
            </div>
            <div class="d-flex align-items-center">
              <img src="@/assets/mail.png" class="icon mr-3" alt="" />
              <div>admin@matchday.co.th</div>
            </div>
          </div>
          <!-- <div class="d-flex align-items-center">
            
          </div> -->
          <!-- <div class="d-flex align-items-center">
           
          </div> -->
          <!-- <ul class="list-group list-group-flush">
            <li class="list-group-item">
              
            </li>
            <li class="list-group-item">
              <div class="d-flex align-items-center">
                <img src="@/assets/phone.png" class="icon mr-3" alt="" />
                <div>093-555-2935</div>
              </div>
            </li>
            <li class="list-group-item">
            
            </li>
          </ul> -->
        </div>
      </div>
    </div>
    <div class="row no-gutters pt-2 pb-2">
      <div class="col p-1 text-center">
        <small><router-link class="text-light p-2" to="/terms"
            rel="nofollow">ข้อตกลงและเงื่อนไขการใช้งาน</router-link><router-link class="text-light p-2" to="/privacy-policy"
            target="_blank" rel="nofollow">นโยบายความเป็นส่วนตัว</router-link></small>
        <small>© 2020 MATCHDAY | All rights reserved </small>
      </div>
    </div>
  </footer>
</template>

<script>
import { getStringAsset } from "@/assets/strings";
export default {
  methods: {
    stringAsset: getStringAsset
  }
};
</script>

<style scoped lang="scss">
.break {
  flex-basis: 100%;
  height: 0;
}

.footer {
  background-color: #E13122;
}

.footer .form-control {
  border-radius: 0.5rem;
  background-color: rgba($color: #fff, $alpha: 0.1);
  width: auto;
  color: var(--light);

  &::placeholder {
    color: var(--light);
  }

  &:focus {
    border-color: var(--light);
  }
}

.list-group li {
  background: none;
  border: none;
  padding-left: 0;
}

.icon {
  width: 25px;
  height: 25px;
  object-fit: contain;
}

.text-xl {
  font-size: 2rem;
}

.text-thin {
  font-weight: 300;
}

.gplay-icon {
  width: 12rem;
}

.ap-icon {
  width: 10rem;
}
</style>
