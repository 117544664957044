<template>
  <div
    class="modal fade"
    id="ChangePasswordModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">เปลี่ยนรหัสผ่าน</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <label for="">รหัสผ่านปัจจุบัน</label>
          <input type="password" class="user-input" />
          <label for="">รหัสผ่านใหม่</label>
          <input type="password" class="user-input" />
          <label for="">ยืนยันรหัสผ่าน</label>
          <input type="password" class="user-input" />
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            ยกเลิก
          </button>
          <button type="button" class="btn btn-primary">บันทึก</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.modal-content {
  background-color: #f9f9f9 !important;
  width: auto;
  display: flex;
}

.modal-header {
  border: none !important;
  background-color: #f9f9f9;
  width: 90%;
  margin: 0rem auto;
}
.modal-body {
  background-color: #fff !important;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 90%;
  margin: 1rem auto;
}

.modal-footer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: none !important;
  background-color: #f9f9f9;
}

.user-input {
  width: 100%;
}

.btn-secondary {
  background-color: transparent !important;
  border: 0px !important;
  color: #e21e1e;
  padding: 0.5rem 2rem;
}

.btn-primary {
  border-radius: 0.2rem;
  background-color: #e21e1e;
  border: 1px solid transparent;
  padding: 0.5rem 2rem;
}

.close {
  font-size: 2rem;
  font-weight: 400;
}
</style>
