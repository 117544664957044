// import { createStore } from "vuex";
// import UserProvider from "@/resources/user_provider";

// const userService = new UserProvider();

// export default createStore({
//   state: {
//     user: {}
//   },
//   mutations: {
//     SET_USER(state, data) {
//       state.user = data;
//     },
//     SET_ELEMENT(state, data) {
//       state.user[data.key] = data.value;
//     }
//   },
//   actions: {
//     async getUser({ commit }) {
//       const data = await userService.getUser();
//       commit("SET_USER", data);
//     },
//     async updateUser(commit, params) {
//       await userService.EditUser(params);
//       commit;
//     }
//   },
//   getters: {
//     GET_USER: state => state.user
//   },
//   modules: {}
// });

import Vuex from "vuex";

import user from "./modules/user";
import sport from "./modules/sport";
import search from "./modules/search";
import location from "./modules/location";
import provider from "./modules/provider";

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  modules: {
    user,
    sport,
    location,
    provider,
    search
  },
  strict: debug
});
