<template>
  <Modal
    ref="modal"
    :class="loading ? 'loading' : ''"
    @onhide="errDismiss"
    maxWidth="500px"
  >
    <div class="d-flex justify-content-end">
      <Spinner color="var(--gray)" v-if="loading"></Spinner>
    </div>
    <div class="alert alert-primary" v-if="error" role="alert">
      <strong>{{ error_msg }}</strong>
    </div>
    <transition name="state">
      <component
        :is="state"
        :mode="mode"
        :user="user"
        @setMode="setMode"
        @goOtp="goOtp"
        @gotoUserInfo="gotoUserInfo"
        @submitUser="submitUser"
        @finish="register"
        @error="errAlert"
        @loading="load"
        @done="done"
      ></component>
    </transition>
    <div class="pl-4 pr-4">
      <div class="mt-1 mb-1">
        <AuthDivider style="color: rgba(0,0,0,0.3)"
          ><AuthDividerLine style="background-color: rgba(0,0,0,0.3)"></AuthDividerLine> หรือ
          <AuthDividerLine style="background-color: rgba(0,0,0,0.3)"></AuthDividerLine
        ></AuthDivider>
      </div>
      <AuthButton
          style="padding: none; text-align: center"
          type="full"
          bg="#fff"
          bd="rgba(0,0,0,0)"
          theme="light"
          @click="$emit('fbLogin')"
          ><img
            src="https://prod-media-as1.s3.ap-southeast-1.amazonaws.com/miscellaneous/Image_35_2x_xgmt1u.png"
            alt="Facebook Logo"
          />
          ลงชื่อเข้าใช้ด้วยบัญชี Facebook
      </AuthButton>
      <AuthButton
        style="padding: none; text-align: center"
        type="full"
        bg="#fff"
        bd="rgba(0,0,0,0)"
        theme="light"
        id="g-auth"
        @click="ggSignIn()"
        ><img
          src="https://prod-media-as1.s3.ap-southeast-1.amazonaws.com/miscellaneous/search_cu4whi.svg"
          alt="Google Logo"
        />
        ลงชื่อเข้าใช้ด้วยบัญชี Google
      </AuthButton>
    </div>
    <div class="modal-footer p-0 pb-3">
      <Divider full></Divider>
      <div
        v-if="!loggedIn()"
        class="
          w-100
          pl-3
          pr-3
          d-flex
          justify-content-between
          align-items-center
        "
      >
        <span class="text-muted">มีบัญชีผู้ใช้อยู่แล้ว?</span>
        <AuthButton
          @click="openLogin"
          theme="light"
          bd="#e21e1e"
          bg="#fff"
          color="#e21e1e"
        >
          เข้าสู่ระบบ
        </AuthButton>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from "../Modal.vue";
import CreateRegister from "./CreateRegister.vue";
import Otp from "./Otp.vue";
import RegisterExtend from "./RegisterExtend.vue";
import { AuthElement, CommonElement } from "../styled";
import UserProvider from "../../resources/user_provider";
import RegisterComplete from "./RegisterComplete.vue";
import { AwsOtp, GoogleOAuth } from "../../services";
export default {
  components: {
    Modal,
    CreateRegister,
    RegisterExtend,
    RegisterComplete,
    Otp,
    ...AuthElement,
    ...CommonElement
  },
  data() {
    return {
      state: "CreateRegister",
      mode: "EmailRegister",
      user: {},
      error: false,
      loading: false,
      error_msg: "มีบางอย่างผิดพลาด"
    };
  },
  emits: ["fbLogin", "googleLogin"],
  methods: {
    setActive(tab) {
      this.active = tab;
    },
    gUser(googleUser) {
      this.$emit("googleLogin", googleUser);
    },
    gError(error) {
      console.log(error);
    },
    async ggSignIn() {
      const gAuth = new GoogleOAuth();
      await gAuth.connect().then(instance => {
        instance.load("auth2", () => {
          let auth2 = gAuth.init();
          auth2
            .signIn()
            .then(GoogleUser => {
              // on success do something
              this.gUser(GoogleUser);
            })
            .catch(error => {
              this.gError(error);
            });
        });
      });
    },
    async show() {
      this.$refs.modal.show();
      /* const gAuth = new GoogleOAuth();
          await gAuth.connect().then(instance => {
            instance.load("auth2", () => {
              let auth2 = gAuth.init();
              auth2.attachClickHandler(
                document.getElementById("g-auth"),
                {},
                googleUser => this.gUser(googleUser),
                error => this.gError(error)
              );
            });
        }); */
    },
    hide() {
      this.$refs.modal.hide();
    },
    openLogin() {
      this.$refs.modal.hide();
      this.$emit("openLogin");
    },
    activeCheck(tab) {
      return tab == this.active ? "active" : "";
    },
    setMode(mode) {
      this.mode = mode;
    },
    errAlert(msg) {
      this.error = true;
      this.error_msg = msg;
    },
    errDismiss() {
      this.error = false;
      this.error_msg = "";
    },
    load() {
      this.loading = true;
    },
    done() {
      this.loading = false;
    },
    async checkPhone() {
      const user_provider = new UserProvider();
      const res = await user_provider.checkPhone(this.user.phone);
      return res;
    },
    async goOtp() {
      if (this.user.phone.length == 10) {
        this.errDismiss();
        this.loading = true;
        const otp = new AwsOtp(this.user.phone, this.user.email);
        const res = await otp.create();
        if (res.status == "success") {
          this.state = "Otp";
        } else if (res.status == "verified") {
          this.gotoUserInfo();
        } else {
          console.log(res.error);
          this.errAlert(res.error.message);
        }
        this.loading = false;
      } else {
        this.errAlert("รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง");
      }
    },
    gotoUserInfo() {
      this.state = "RegisterExtend";
    },
    submitUser(user) {
      for (const key in user) {
        if (Object.hasOwnProperty.call(user, key)) {
          this.user[key] = user[key];
        }
      }
    },
    async register() {
      const user_provider = new UserProvider();
      this.loading = true;
      const res = await user_provider
        .register(this.user)
        .catch(err => console.log(err));
      this.loading = false;
      if (res.status == "success") {
        localStorage.setItem("user_token", res.token.token);
        this.state = "RegisterComplete";
      } else {
        console.log(res);
        this.errAlert("มีบางอย่างผิดพลาด");
      }
    },
    loggedIn() {
      return localStorage.getItem("user_token") ? true : false;
    }
  }
};
</script>
<style lang="scss" scoped>
@keyframes state {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes state-out {
  from {
    opacity: 1;
    transform: translateX(0);
    max-height: 300px;
  }
  to {
    opacity: 0;
    transform: translateX(-100%);
    max-height: 100%;
  }
}
.state-enter-active {
  animation: state 0.25s ease;
}
.state-leave-active {
  animation: state-out 0.25s ease;
}
.loading {
  pointer-events: none;
}
</style>
