<template>
  <div class="myprofile-wrapper">
    <div class="main-profile-card">
      <div class="profile">
        <img
          class="profile-img"
          :src="this.user_detail.avatar? this.user_detail.avatar:this.default_avatar"
        />
        <div class="name-section">
          <div class="title-edit">
            <h6 class="username-title">ชื่อผู้ใช้</h6>
            <div data-toggle="modal" data-target="#ChangeNameModal"><img style="height: 1rem; cursor: pointer;" :src="edit_icon" /></div>
          </div>
          <p class="username">{{ this.user_detail.fullname }}</p>
        </div>
      </div>
    </div>

    <div class="main-profile-card">
      <div class="profile">
        <div class="name-section">
          <div class="title-edit">
            <h6 class="username-title">อีเมล </h6>
            <div data-toggle="modal" data-target="#ChangeEmailModal"><img style="height: 1rem; cursor: pointer;" :src="edit_icon" /></div>
          </div>
          <p class="username">{{ this.user_detail.email }}</p>
        </div>
      </div>
    </div>

    <div class="main-profile-card">
      <div class="profile">
        <div class="name-section">
          <div class="title-edit">
            <h6 class="username-title">หมายเลขโทรศัพท์ </h6>
            <div data-toggle="modal" data-target="#ChangePhoneModal"><img style="height: 1rem; cursor: pointer;" :src="edit_icon" /></div>
          </div>
          <p class="username">{{ this.user_detail.phone_number }}</p>
        </div>
      </div>
    </div>

    <div class="main-profile-card">
      <div class="profile">
        <div class="name-section">
          <h6 class="username-title">รหัสผ่าน</h6>
          <p class="username">*********</p>
        </div>
      </div>
    </div>
    <ChangeNameModal />
    <ChangPhonenumberModal />
    <ChangePasswordModal />
    <ChangeEmailModal />
    <ChangeLangModal />
  </div>
</template>

<script>
import ChangPhonenumberModal from "./Modals/ChangePhonenumberModal";
import ChangePasswordModal from "./Modals/ChangePasswordModal";
import ChangeEmailModal from "./Modals/ChangeEmailModal";
import ChangeNameModal from "./Modals/ChangeNameModal";
import ChangeLangModal from "./Modals/ChangeLangModal";
import thaiFlagIcon from "../../../assets/icons/thailand.svg";
import engFlagIcon from "../../../assets/icons/uk.svg";
import chineseFlagIcon from "../../../assets/icons/china.svg";
import { /*mapState,*/ mapGetters, mapActions } from "vuex";

export default {
  data: () => {
    return {
      logged_in: localStorage.getItem("user_token") ? true : false,
      dob: null,
      edit_icon: 'https://e7.pngegg.com/pngimages/461/1024/png-clipart-computer-icons-editing-edit-icon-cdr-angle.png',
      default_avatar: 'https://cdn.discordapp.com/attachments/780994260471775272/989465668854374420/player.png',
      langs: {
        th: {
          lang: "ภาษาไทย",
          flag: thaiFlagIcon
        },
        en: {
          lang: "English",
          flag: engFlagIcon
        },
        cn: {
          lang: "中文",
          flag: chineseFlagIcon
        }
      },
      following: {}
    };
  },
  computed: {
    ...mapGetters({
      user_detail: "user/GET_USER"
    })
    // ...mapState({
    //   user: state => state.user
    // })
  },
  created() {
    if (this.logged_in) {
      this.updateUser();
    }
  },
  components: {
    ChangPhonenumberModal: ChangPhonenumberModal,
    ChangePasswordModal: ChangePasswordModal,
    ChangeEmailModal: ChangeEmailModal,
    ChangeLangModal: ChangeLangModal,
    ChangeNameModal: ChangeNameModal
  },
  methods: {
    updateUser() {
      this.logged_in = true;
      this.getUser().then(() => {
        this.dob = this.user_detail.dob;
      });
    },
    ...mapActions({
      getUser: "user/getUser"
    })
  }
};
</script>

<style scoped>
  .title-edit > h6 {
    margin: 0;
    margin-right: 0.5rem
  }
  .title-edit {
    display: flex;
    align-items: center;
  }
p {
  margin: 0 !important;
}
.myprofile-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1rem;
}
.main-profile-card {
  width: 100%;
  padding: 1rem;
  background-color: #fff;
  filter: drop-shadow(0px 3px 20px rgba(0, 0, 0, 0.16));
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  margin-bottom: 2rem;
}
.profile {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.profile-img {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  margin-left: 1rem;
}

.name-section {
  margin-left: 2rem;
}

.edit {
  position: absolute;
  top: 8%;
  right: 4%;
  font-size: 0.75rem;
  color: #2e2e2e;
  background-color: transparent !important;
  border: 0px solid !important;
}

.flag-icon {
  display: flex;
  width: 40px;
  margin-left: 2rem;
}
</style>
