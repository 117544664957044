import partner from "./partner.json";
import common from "./common.json";
import sports from "./sports.json";
import aboutus from "./aboutus.json";

const stringAssets = {
  partner,
  common,
  sports,
  aboutus
};

/**
 * @param { string } key Uniuqe key of string.
 * @param { string } lang Language of string.
 * */
export function getStringAsset(key, lang) {
  const splited = key.split("/");
  if (!lang) lang = "th";
  const notFound = {
    key: key,
    content: {
      en: "String missing",
      th: "ไม่พบข้อความ"
    }
  };
  const asset = stringAssets[splited[0]];
  if (!asset) return notFound.content[lang];
  const data = asset.find(x => x.key === splited[1]);
  if (!data) return notFound.content[lang];
  return data.content[lang];
}
/**
 * @param { string } name Name of string set.
 * */
export function getStringAssetSet(name) {
  const set = stringAssets[name];
  if (set) return set;
}
