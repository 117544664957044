<template>
  <div class="myreservation col-12">
    <Sidebar />
    <div class="my-reserve-wrapper col-12 col-md-9">
      <div class="top-controller">
        <div class="left-section">
          <div class="filters-btn-box">
            <button
              type="button"
              @click="activeFilter('coming')"
              :class="`filter-btn ${filters.incoming ? 'active' : ''}`"
            >
              กำลังจะมาถึง
            </button>
            <button
              type="button"
              :class="`filter-btn ${filters.ended ? 'active' : ''}`"
              @click="activeFilter('ended')"
            >
              แมทช์ที่จบแล้ว
            </button>
            <button
              type="button"
              :class="`filter-btn ${filters.canceled ? 'active' : ''}`"
              @click="activeFilter('cancel')"
            >
              ยกเลิก
            </button>
          </div>
          <div class="search-section">
            <div class="title-text-wrap">
              <h5 class="title-text-content">หมายเลขการจอง</h5>
            </div>
            <div class="search-bar-wrap">
              <div class="serchbar-box">
                <input type="text" placeholder="หมายเลขการจอง" />
              </div>
              <div class="action-btn-wrap">
                <button type="button" class="search-btn">ค้นหา</button>
                <button type="button" class="edit-btn">แก้ไข</button>
              </div>
            </div>
          </div>
        </div>
        <div class="right-section">
          <div class="right-filters-btn-section">
            <div class="right-filter-btn-wrap">
              <button type="button" disabled>เรียงตาม</button>
              <button
                type="button"
                :class="`right-filter-btn ${filters.dateTime ? 'active' : ''}`"
                @click="activeFilter('dateTime')"
              >
                วันที่จอง
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-section-wrapper">
        <div class="card-list" v-for="({ total_price, sport, court, court_img, day, match_status, time_start, time_end, id }, index) in historySpitedList" :key="index" >
          <ReserveCard
            v-if="id"
            :court="court"
            :court_img="court_img"
            :day="day"
            :status="match_status"
            :start="time_start"
            :end="time_end"
            :id="id"
            :sport="sport"
            :price="total_price"
          />
          <span v-else>- ยังไม่มีแมทช์ -</span>
        </div>

        <div class="pagination-section">
          <nav aria-label="Page navigation example" style="width: 50%">
            <ul class="pagination">
              <li class="page-item arrow-btn">
                <a
                  class="page-link"
                  @click="goPrevPage()"
                  aria-label="Previous"
                >
                  <span aria-hidden="true"
                    ><i class="fas fa-caret-left"></i
                  ></span>
                  <span class="sr-only">Previous</span>
                </a>
              </li>
              <div class="page-list w-100">
                <li class="page-item">
                  <button
                    :class="`page-link ${activePage === index ? 'active' : ''}`"
                    v-for="index in allPageCount"
                    :key="index"
                    @click="changeReviewPage(index)"
                  >
                    {{ index }}
                  </button>
                </li>
              </div>
              <li class="page-item arrow-btn">
                <a class="page-link" @click="goNextPage()" aria-label="Next">
                  <span aria-hidden="true"
                    ><i class="fas fa-caret-right"></i
                  ></span>
                  <span class="sr-only">Next</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReserveCard from "../components/ReserveCard/ReserveCard";
import Sidebar from "../components/SideBar";
import HistoryProvider from "../resources/history_provider";
import UserProvider from '../resources/user_provider';

export default {
  components: {
    ReserveCard: ReserveCard,
    Sidebar: Sidebar
  },
  data() {
    return {
      historyList: [],
      historySpitedList: [],
      allPageCount: 0,
      itemPerPage: 0,
      pageNumber: 1,
      activePage: 1,
      filters: {
        dateTime: true,
        incoming: true,
        ended: false,
        canceled: false
      }
    };
  },
  methods: {
    async getHistory() {
      const provider = new HistoryProvider();
      await provider.getHistory('all')
        .then(res => {
          for (var key in this.filters) {
            if (this.filters[key]) this.historyList = res.filter(({ match_status }) => match_status == key)
          }
          this.Paginator(this.historyList);
        });
    },
    Paginator(items, Inpage) {
      let page = Inpage || 1,
      per_page = 5,
      offset = (page - 1) * per_page,
      paginatedItems = items.slice(offset).slice(0, per_page),
      total_pages = Math.ceil(items.length / per_page);
      this.allPageCount = total_pages;
      this.historySpitedList = paginatedItems;
    },

    changeReviewPage(page) {
      this.pageNumber = page;
      this.activePage = page;
      this.Paginator(this.historyList, this.pageNumber);
    },
    goNextPage() {
      if (this.pageNumber < this.allPageCount) {
        this.activePage += 1;
        this.pageNumber += 1;
      } else {
        this.activePage = 1;
        this.pageNumber = 1;
      }
      this.Paginator(this.historyList, this.pageNumber);
    },

    async activeFilter(filter) {
      this.historyList = [];
      this.historySpitedList = [];
      for (const key in this.filters) {
        if (!['dateTime'].includes(key)) {
          this.filters[key] = false;
          if (key === filter) {
            this.filters[key] = true;
          }
          this.filters['dateTime'] = true;
        }
      }

      if (['dateTime'].includes(filter)) {
        this.filters['dateTime'] = false;
        this.filters[filter] = true;
      }

      await this.getHistory();
    },

    goPrevPage() {
      if (this.pageNumber <= this.allPageCount && this.pageNumber > 1) {
        this.activePage -= 1;
        this.pageNumber -= 1;
      } else {
        this.activePage = 1;
        this.pageNumber = 1;
      }
      this.Paginator(this.historyList, this.pageNumber);
    }
  },
  async mounted() {
    await this.getHistory();
  }
};
</script>

<style scoped lang="scss">
  .card-section-wrapper {
    min-height: 73vh;
    display: flex;
    flex-direction: column;
    justify-content: end;
  }
.myreservation {
  display: flex;
  padding: 0;
  background-color: #f7f7f7;
}
.my-reserve-wrapper {
  width: 100%;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;

  .top-controller {
    width: 100%;
    margin: 1rem;
    display: flex;

    .left-section {
      flex: 1 1 60%;
    }

    .right-section {
      flex: 1 1 40%;
    }
  }
}

.filters-btn-box {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.filter-btn {
  border: none;
  border-bottom: 1px solid #707070;
  background-color: transparent;
  padding: 1rem;
  font-size: 1rem;
  clear: both;
  width: 30%;
}

.filter-btn.active {
  color: #2e2e2e;
  border-bottom: 1px solid #d60326;
}

.search-section {
  width: 100%;
  display: flex;
  margin: 1rem;
  flex-direction: column;
}

.search-bar-wrap {
  display: flex;
  width: 100%;

  .serchbar-box {
    margin-right: 1rem;
    width: 100%;

    input {
      background-color: #fff;
      border: 1px #707070 solid;
      padding: 0.5rem 1rem;
      width: 100%;
    }

    input::placeholder {
      color: #707070;
    }
  }

  .action-btn-wrap {
    width: 100%;
    * {
      margin-right: 0.5rem;
      padding: 0.5rem 0.75rem;
      border: none;
      border-radius: 0.3rem;
    }
  }
}

.search-btn {
  background-color: #d60326;
  color: #fff;
}

.edit-btn {
  background-color: #fff;
  border: 1px #707070 solid !important;
  color: rgba(0, 0, 0, 0.5);
}

.title-text-content {
  font-size: 1rem;
}

.right-section {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100%;
}

.right-filter-btn-wrap-section {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.right-filter-btn-wrap {
  width: 100%;
  display: flex;
  justify-content: flex-end;

  button {
    padding: 0.5rem 1rem;
    border: 1px #7070706b solid;
    background-color: #fff;
  }
}

.right-filter-btn.active {
  background-color: #d6032681;
  color: #fff;
}

.pagination-section {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
}

.page-link.active {
  background-color: #d60326 !important;
  border-radius: 100%;
  color: #fff;
}

.page-item,
.page-link {
  border: 1px transparent solid !important;
  background-color: transparent !important;
  color: #707070;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0rem 0.5rem;
}

.page-list {
  position: relative;
  min-width: 15rem !important;
  display: flex;
  justify-content: center;
}

.arrow-btn {
  cursor: pointer;
  border: 1px #707070 solid !important;
  border-radius: 100% !important;
}
</style>
