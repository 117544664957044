<template>
  <div>
    <div class="form-group">
      <label>อีเมลของท่าน</label>
      <AuthInput class="login-class" v-model="email" type="email" placeholder="example@mail.com" />
    </div>
    <div class="form-group">
      <label>ชื่อ</label>
      <AuthInput
        class="login-class" v-model="first_name"
        type="text"
        placeholder="ระบุชื่อของท่าน"
      />
    </div>
    <div class="form-group">
      <label>นามสกุล</label>
      <AuthInput
        class="login-class" v-model="last_name"
        type="text"
        placeholder="ระบุนามสกุลของท่าน"
      />
    </div>
    <div class="form-group">
      <label>เบอร์โทรศัพท์</label>
      <AuthInput
        class="login-class" v-model="phone_number"
        type="number"
        placeholder="ระบุเบอร์โทรศัพท์"
      />
    </div>
    <div class="form-group">
      <label>รหัสผ่าน</label>
      <AuthInput class="login-class" v-model="password" :type="showPassword" />
      <small class="text-warning" v-if="checkStrength()"
        >รหัสไม่ผ่านความต้องการขั้นต่ำ</small
      >
      <div class="d-flex justify-content-between">
        <small style="color: rgba(0, 0, 0, 0.3)"
          >รหัสผ่านควรมีจำนวนตัวอักษร 8-12 ตัว <br />
          และควรมีทั้งตัวเลขและตัวอักษร</small
        >
        <label for="show-password" style="display: flex; align-items: center; margin: none">
          <AuthCheckbox id="show-password" type="checkbox" style="border-radius: 30px;margin: 9px;height: 9px;width: 9px;" @change="togglePasswordVisibility" />
          <small>แสดงรหัสผ่าน</small>
        </label>
      </div>
    </div>
    <div>
      <AuthButton
        theme="dark"
        type="full"
        bg="#e21e1e"
        :class="accept && !checkStrength() ? '' : 'disabled'"
        @click="submitUser"
        >สร้างบัญชีผู้ใช้</AuthButton
      >
    </div>
    <div class="d-flex" style="display: flex; align-items: center; margin: none">
      <input id="tcpv" style="border-radius: 30px;margin: 9px;height: 9px;width: 9px;" class="auth-checkbox" v-model="accept" type="checkbox" />
      <label for="tcpv" style="margin: 0">
        <small>
          ท่านยอมรับ <AuthLink style="color: #e21e1e" href="/terms" target="_blank">ข้อกำหนดการใช้งาน</AuthLink> และ
          <AuthLink style="color: #e21e1e" href="/privacy-policy" target="_blank">นโยบายความเป็นส่วนตัว</AuthLink> ของ แมทช์เดย์
        </small>
      </label>
    </div>
  </div>
</template>

<script>
import { AuthElement, CommonElement } from "../styled";
import { passwordStrength } from "check-password-strength";
import UserProvider from "../../resources/user_provider";
export default {
  components: { ...AuthElement, ...CommonElement },
  data() {
    return {
      email: null,
      password: null,
      passwordValidate: "",
      first_name: null,
      last_name: null,
      phone_number: null,
      showPassword: "password",
      accept: false,
      existPhone: false,
      existEmail: false
    };
  },
  emits: ["submitUser"],
  methods: {
    togglePasswordVisibility() {
      if (this.showPassword == "password") {
        this.showPassword = "text";
      } else {
        this.showPassword = "password";
      }
    },
    async submitUser() {
      if (this.checkInput()) {
        await this.checkExist();
        if (this.existPhone || this.existEmail) {
          let msg = "มี ";
          if (this.existPhone) msg += "หมายเลข ";
          if (this.existEmail) msg += "อีเมล ";
          msg += "ในระบบอยู่แล้ว";
          this.$parent.$emit("error", msg);
        } else {
          this.$emit("submitUser", {
            email: this.email,
            password: this.password,
            firstname: this.first_name,
            lastname: this.last_name,
            phone: this.phone_number,
            policy_accept: this.accept
          });
        }
      } else {
        this.$parent.$emit("error", "กรุณากรอกข้อมูลให้ครบ");
      }
    },
    checkStrength() {
      return this.passwordValidate == "Too weak" ? true : false;
    },
    async checkExist() {
      this.$parent.$emit("loading");
      const user_provider = new UserProvider();
      this.existPhone = await user_provider.checkExist(
        this.phone_number,
        "phone"
      );
      this.existEmail = await user_provider.checkExist(this.email, "email");
      this.$parent.$emit("done");
    },
    async checkInput() {
      let check = false;
      if (
        this.email &&
        this.password &&
        this.first_name &&
        this.last_name &&
        this.phone_number &&
        this.phone_number.length > 8 &&
        !this.checkStrength()
      ) {
        check = true;
      }
      return check;
    }
  },
  watch: {
    phone_number(newVal, oldVal) {
      if (newVal.toString().length <= 10) {
        this.phone_number = newVal
      } else {
        this.phone_number = oldVal
      }
    },
    password(input) {
      this.passwordValidate = passwordStrength(input).value;
    }
  }
};
</script>

<style scoped>
  .login-class {
    padding: 0.5rem;
    background-color: #f7f4f4;
    box-shadow: 0px 1px 1px #00000029;
    border: none;
    border-radius: 5px;
  }
</style>
