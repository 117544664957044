<template>
  <div>
    <div class="form-group">
      <label>หมายเลขโทรศัพท์ของท่าน</label>
      <AuthPhoneInputWrapper class="login-class" style="padding: 0;border-radius: 5px">
        <AuthCountryPhoneFront style="background-color: white;border: 2px solid #f2f2f2;border-radius: 5px 0 0 5px;">
          <img src="https://purecatamphetamine.github.io/country-flag-icons/3x2/TH.svg" />+66
        </AuthCountryPhoneFront>
        <AuthPhoneInput v-model="phone" type="number" />
      </AuthPhoneInputWrapper>
    </div>
    <div class="form-group">
      <div class="d-flex justify-content-between">
        <label>รหัสผ่าน</label>
        <AuthLink href="/forget-password" tabindex="-1"><small style="color: #e21e1e">ลืมรหัสผ่าน?</small></AuthLink>
      </div>
      <AuthInput @keydown="enter" class="login-class" v-model="password" :type="showPassword" />
      <div class="d-flex justify-content-end">
        <label for="show-password" style="display: flex; align-items: center">
          <AuthCheckbox
            style="border-radius: 30px;margin: 9px;height: 9px;width: 9px;"
            @change="togglePasswordVisibility"
            type="checkbox"
            id="show-password"
          />
          <small>แสดงรหัสผ่าน</small>
        </label>
      </div>
    </div>
    <AuthButton
      style="margin: 0 !important;border-radius: 5px"
      theme="dark"
      type="full"
      bg="#e21e1e"
      class="mt-5"
      @click="login"
      >เข้าสู่ระบบ</AuthButton
    >
    <small style="display: flex; justify-content: center; margin-top: 0.75rem;">
      ท่านยอมรับ <AuthLink style="color: #e21e1e" href="/terms" target="_blank">ข้อกำหนดการใช้งาน</AuthLink> และ
      <AuthLink style="color: #e21e1e" href="/privacy-policy" target="_blank">นโยบายความเป็นส่วนตัว</AuthLink> ของ แมทช์เดย์
      เมื่อดำเนินการต่อ
    </small>
  </div>
</template>

<script>
import { AuthElement, CommonElement } from "../styled";
export default {
  components: { ...AuthElement, ...CommonElement },
  data() {
    return {
      phone: "",
      password: "",
      showPassword: "password"
    };
  },
  emits: ["login"],
  methods: {
    togglePasswordVisibility() {
      if (this.showPassword == "password") {
        this.showPassword = "text";
      } else {
        this.showPassword = "password";
      }
    },
    login() {
      this.$emit("login", {
        username: this.phone,
        password: this.password
      });
    },
    enter(key) {
      if (key == "Enter") {
        this.login();
      }
    }
  }
};
</script>

<style scoped>
  .login-class {
    padding: 0.5rem;
    background-color: #f7f4f4;
    box-shadow: 0px 1px 1px #00000029;
    border: none;
    border-radius: 5px;
  }
  input[type=checkbox]:checked {
    background-color: #e21e1e;
  }
  input[type=checkbox]:focus {
    border-color: #e21e1e;
  }
</style>
