export const api_url = process.env.VUE_APP_BASE_API;

export const aws_config = {
  identityPoolId: process.env.VUE_APP_AWS_IDENTITY_POOL_ID,
  region: process.env.VUE_APP_AWS_REGION,
  identityPoolRegion: process.env.VUE_APP_AWS_IDENTITY_POOL_REGION,
  userPoolId: process.env.VUE_APP_AWS_USER_POOL_ID,
  userPoolWebClientId: process.env.VUE_APP_AWS_POOL_WEB_CLIENT_ID
};

export const facebook_app_id = process.env.VUE_APP_FACECBOOK_APP_ID;

export const map_api_key = process.env.VUE_APP_GOOGLE_API_KEY;

export const google_app_id = process.env.VUE_APP_GOOGLE_APP_ID;
