<template>
  <aside class="sidebar-menu col-12 col-md-3">
    <ul class="menu-items-wrapper">
      <router-link to="profile" :class="
        `${currentPath.fullPath === '/profile'
          ? 'menu-item profile active'
          : 'menu-item profile '
        }`
      ">
        <span><img src="../assets/icons/metro-profile.svg" alt="" class="menu-icon" /> </span><a
          class="d-none d-md-flex">โปรไฟล์ของฉัน</a>
        <div class="right-box"></div>
      </router-link>
      <router-link to="my-reservation" :class="
        `menu-item reserve ${currentPath.fullPath === '/my-reservation' ? 'active' : ''
        }`
      ">
        <span><img src="../assets/icons/metro-pin.svg" alt="" class="menu-icon" /></span><a
          class="d-none d-md-flex">การจองของฉัน</a>
        <div class="right-box"></div>
      </router-link>
      <router-link style="pointer-events: none; cursor: default" class="menu-item review" to="review" :class="
        `menu-item review ${currentPath.fullPath === '/review' ? 'active' : ''
        }`
      ">
        <span><img src="../assets/icons/ionic-ios-star.svg" alt="" class="menu-icon" /></span><a href=""
          class="d-none d-md-flex" style="color:grey">รีวิวสนาม</a>
        <div class="right-box"></div>
      </router-link>
      <router-link style="pointer-events: none; cursor: default" :class="
        `menu-item question ${currentPath.fullPath === '/question' ? 'active' : ''
        }`
      " to="question">
        <span><img src="../assets/icons/awesome-question-circle.svg" alt="" class="menu-icon" /></span><a href=""
          class="d-none d-md-flex" style="color:grey">คำถามที่พบบ่อย</a>
        <div class="right-box"></div>
      </router-link>
      <div :class="`menu-item term`">
        <span><img src="../assets/icons/open-flag.svg" alt="" class="menu-icon" /></span><a href="/terms"
          target="_blank" class="d-none d-md-flex">ข้อตกลงและเงื่อนไข</a>
        <div class="right-box"></div>
      </div>
      <div :class="`menu-item policy`">
        <span><img src="../assets/icons/awesome-lock.svg" alt="" class="menu-icon" /></span><a href="/privacy-policy"
          target="_blank" class="d-none d-md-flex">นโยบายความเป็นส่วนตัว</a>
        <div class="right-box"></div>
      </div>
      <hr class="divider d-none d-md-block" />
    </ul>
  </aside>
</template>

<script>
export default {
  data() {
    return {
      currentPath: this.$router.currentRoute
    };
  },
  mounted() { }
};
</script>

<style lang="scss" scoped>
a.disabled {
  pointer-events: none;
  cursor: default;
}

.sidebar-menu {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  background-color: #fff;
  padding: 0;

  @media (max-width: 767px) {
    width: 100vw !important;
    min-height: 0;
    height: 50px !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
    flex-direction: row !important;
    position: fixed;
    bottom: 0;
    z-index: 999 !important;
  }
}

.menu-item {
  list-style: none;
  cursor: pointer;
}

.menu-items-wrapper {
  padding: 0;
  width: 100%;
  height: 100%;

  @media (max-width: 767px) {
    position: relative;
    display: flex;
    padding-bottom: 0;
    width: 100vw;
    height: 50px;
    margin-bottom: 0;
  }
}

.menu-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 1rem;
  position: relative;
  transition: 1s all ease;

  @media (max-width: 767px) {
    flex: 1;
    margin-bottom: 0;
    justify-content: center;
  }
}

.divider {
  height: 1px;
  width: 90%;
  border-bottom: 1px #707070 solid;
}

.menu-item a {
  font-size: 1rem;
  margin-left: 1rem;
  color: #2e2e2e;
  margin: 1rem;
}

.menu-item.active {
  background-color: #ffe3df;
}

.menu-item.active a {
  color: #d60326;
  -webkit-text-fill-color: #d60326;
  user-select: none;
}

.menu-item.active span>img {
  filter: invert(70%) grayscale(100%) brightness(40%) sepia(100%) hue-rotate(-50deg) saturate(400%) contrast(2);
  -webkit-filter: invert(70%) grayscale(100%) brightness(40%) sepia(100%) hue-rotate(-50deg) saturate(400%) contrast(2);
}

.right-box {
  display: none;
  transition: 1s all ease;
}

.menu-item.active .right-box {
  position: absolute;
  display: block;
  width: 0.75rem;
  height: 100%;
  background-color: #d60326;
  right: 0;

  @media (max-width: 767px) {
    width: 100% !important;
    height: 0.25rem;
    bottom: 0;
  }
}
</style>
