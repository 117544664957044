import { LocationService } from "../../services";

const locationService = new LocationService();

const state = {
  location: {
    lat: 13.7241028,
    lng: 100.5281218
  },
  allowCurrentLocation: false
};

const getters = {
  location: state => state.location,
  allowCurrentLocation: state => state.allowCurrentLocation
};

const actions = {
  async getLocation({ dispatch, commit }) {
    let response;

    try {
      response = await locationService.accessLocation();
    } catch (error) {
      // Handle error
      let default_latlng = {
        lat: 13.7241028,
        lng: 100.5281218
      };

      commit("SET_LOCATION", default_latlng);
      commit("SET_ALLOW_CURRENT_LOCATION", false);
      await dispatch("provider/getRecommendedPlaces", default_latlng, {
        root: true
      });
      console.log(error);
      return;
    }

    // Handle success
    commit("SET_LOCATION", response);
    commit("SET_ALLOW_CURRENT_LOCATION", true);
    await dispatch("provider/getRecommendedPlaces", response, { root: true });
  }
};

const mutations = {
  SET_LOCATION(state, payload) {
    state.location = payload;
  },
  SET_ALLOW_CURRENT_LOCATION(state, payload) {
    state.allowCurrentLocation = payload;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
