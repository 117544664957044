<template>
  <div class="reserve-card-wrapper">
    <div class="reserve-card">
      <div class="card-content">
        <div class="card-img-wrap">
          <img
            :src="court_img"
            class="card-img"
          />
        </div>
        <div class="reserve-details">
          <div class="main-details">
            <div class="title-detail"> 
              <h3 class="field-name">
                {{ court }}
              </h3>
            </div>
            <div class="desc-detail">
              <div class="text-wrap">
                <p class="desc reserce-no">หมายเลขการจอง : {{ id }}</p>
                <p class="desc reserce-no">สนาม{{ sport }}x 1 สนาม</p>
                <p class="desc reserce-no">{{ totalTime }} ชั่วโมง</p>
              </div>
              <div class="match-status-wrap">
                <div class="status" v-if="status === 'ended'">
                  <i class="fas fa-check-circle"></i>
                  <span>แมทช์จบแล้ว</span>
                </div>
                <div class="status" v-if="status !== 'ended'">
                  <i class="fas fa-check-circle"></i>
                  <span>แมทช์ยังไม่จบ</span>
                </div>
              </div>
            </div>
          </div>
          <div class="reserve-time-details">
            <div class="desc-reserve-time">
              <p class="desc">วันที่ {{ day }}</p>
              <p class="desc">เวลา {{ start }}น.</p>
              <p class="desc">ถึง {{ end }}น.</p>

              <div class="total-price">
                <h6><span>THB</span>{{ price }}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="card-footer">
        <div class="button-section">
          <a href="#">รีวิวสนาม</a>
          <a href="#">ส่งใบเสร็จออนไลน์</a>
          <a href="#">จองอีกครั้ง</a>
        </div>
        <div class="reserve-view-btn-wrap">
          <button class="reserve-view-btn">ดูข้อมูลการจอง</button>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  props: ["court", 'court_img', "day", "status", "start", "end", "id", "sport", "price"],
  data() {
    return {
      totalTime: Math.abs(
        parseInt(this.end.slice(0, 2)) - parseInt(this.start.slice(0, 2))
      )
    };
  }
};
</script>

<style scoped lang="scss">
.reserve-card-wrapper {
  margin: 1rem;
  width: 98%;
  background: #fff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}
.reserve-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 70vw;
  width: 100%;
}
.card-content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 1rem;
}
.card-img-wrap {
  position: relative;
  width: 18rem;
  height: 10rem;
  flex: 1 1 35%;
  margin-right: 2rem;
  .card-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.hot-deals {
  position: absolute;
  top: 3%;
  padding: 0.5rem 1rem;
  background-color: #ee4b32;
  border-radius: 0.3rem;
  color: #fff;
  display: flex;
  align-items: center;
  .hot-deals-text {
    font-size: 0.85rem;
    margin-bottom: 0;
    padding-left: 0.4rem;
  }
}
.reserve-details {
  display: flex;
  justify-content: center;
  align-content: center;
  flex: 1 1 65%;
}
.main-details {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  flex: 1 1 70%;
}
.reserve-time-details {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.field-name {
  font-size: 1.25rem;
}
.desc {
  font-size: 0.75rem;
  margin: 0;
  margin-bottom: 0.2rem;
  opacity: 0.5;
}
.status {
  color: #009782;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 0.85rem;
}
.fa-check-circle {
  padding-right: 0.5rem;
  width: 2rem;
  height: 2rem;
}
.card-footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
}
.button-section {
  a:first-child {
    border-left: 0px;
  }
  a {
    padding: 0rem 1rem;
    border-left: 1px #707070 solid;
  }
}
.reserve-view-btn {
  padding: 0.5rem;
  border: none;
  background: #009782;
  border: 0.4px solid #707070;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 0.3rem;
  color: #fff;
}
.total-price {
  margin-top: 5rem;
  h6 {
    color: #ee4b32;
    span {
      color: #707070;
      padding-right: 0.5rem;
      font-weight: 300;
    }
  }
}
</style>