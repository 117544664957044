<template>
  <transition name="modal">
    <ModalWrapper v-if="showing" ref="wrapper">
      <ModalDialog ref="dialog">
        <ModalContent class="mdcT">
          <div class="d-flex justify-content-end">
            <Spinner color="var(--gray)" v-if="$parent.loading"></Spinner>
          </div>
          <div class="alert alert-primary" v-if="error" role="alert">
            <strong>หมายเลข OTP ไม่ถูกต้อง</strong>
          </div>
          <div class="modal-header pr-4 pl-4">
            <h5 class="modal-title">การยืนยันตัวตน</h5>
          </div>
          <div class="modal-body pr-4 pl-4">
            <div class="d-flex justify-content-between">
              <div class="col-auto p-0">
                เราได้ส่งรหัสยืนยันไปที่โทรศัพท์หมายเลข
              </div>
              <div class="col text-center p-0 tell">{{ user.phone }}</div>
            </div>
            <div>รหัสนี้จะหมดภายใน 3 นาที</div>
            <div class="d-flex justify-content-between mt-4">
              <AuthOtpInput
                type="tel"
                maxlength="1"
                v-for="input in otpInput"
                :key="input.id"
                :id="input.id"
                class="otp-digit"
                @keydown="next($event, input.prev, input.next, input.id)"
              />
            </div>
            <AuthButton
              theme="dark"
              type="full"
              bg="#e21e1e"
              class="mt-5"
              @click="checkOtp"
              >ยืนยัน</AuthButton
            >
            <div class="mt-4">ยังไม่ได้รหัสยืนยัน?</div>
            <div>
              <AuthLink href="">ส่งรหัสอีกครั้ง</AuthLink>
            </div>
            <div>
              <AuthLink href="">ใช้หมายเลขอื่น</AuthLink>
            </div>
          </div>
        </ModalContent>
      </ModalDialog>
    </ModalWrapper>
  </transition>
</template>

<script>
import { ModalElement, AuthElement, CommonElement } from "./styled";
import { AwsOtp } from "../services";
export default {
  components: { ...ModalElement, ...AuthElement, ...CommonElement },
  props: {
    user: Object
  },
  computed: {
    otpInput() {
      let input = [];
      for (let index = 1; index <= 6; index++) {
        input.push({
          id: `otp-${index}`,
          prev: `#otp-${index > 1 ? index - 1 : index}`,
          next: `#otp-${index < 6 ? index + 1 : index}`
        });
      }
      return input;
    }
  },
  data() {
    return {
      showing: false,
      error: false
    };
  },
  emits: ["done"],
  methods: {
    bodyOverride() {
      document.body.style.paddingRight = `${window.innerWidth -
        document.body.clientWidth}px`;
      document.body.style.overflow = "hidden";
    },
    removeBodyOverride() {
      document.body.style.paddingRight = "";
      document.body.style.overflow = "";
    },
    show() {
      this.showing = true;
    },
    hide() {
      this.showing = false;
    },
    next(event, prev, next, id) {
      let current = document.getElementById(id);
      if (event.key != "Backspace") {
        setTimeout(() => {
          document.querySelector(next).focus();
        }, 100);
      } else if (event.key == "Backspace") {
        event.preventDefault();
        if (current.value != "") {
          current.value = "";
        } else {
          document.querySelector(prev).focus();
        }
      }
    },
    async checkOtp() {
      this.$parent.loading = true;
      let digit = "";
      document.querySelectorAll(".otp-digit").forEach(item => {
        digit += item.value;
      });
      const aws_otp = new AwsOtp(this.user.phone, this.user.email);
      const confirm = await aws_otp.auth(digit);
      if (confirm) {
        this.$emit("done");
      } else {
        this.error = true;
      }
      this.$parent.loading = true;
    }
  },
  watch: {
    showing(isShow) {
      isShow ? this.bodyOverride() : this.removeBodyOverride();
    }
  }
};
</script>
<style lang="scss" scoped>
@keyframes modal-fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes modal-bonce {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
.modal-enter-active {
  animation: modal-fade 0.25s linear;
  .mdcT {
    animation: modal-bonce 0.25s ease;
  }
}
.modal-leave-active {
  animation: modal-fade 0.25s reverse;
  .mdcT {
    animation: modal-bonce 0.25s reverse;
  }
}
.dalwrpr {
  box-sizing: content-box;
}
.tell {
  color: #e21e1e;
}
</style>
