<template>
  <div class="profile-wrapper col-12">
    <Sidebar />
    <section class="profile-contents col-12 col-md-9">
      <!-- <FollowingFriends /> -->
      <MyProfile />
    </section>
  </div>
</template>
<script>
// import FollowingFriends from "../components/Profile/FollowingFriends/FollowingFriend";
import MyProfile from "../components/Profile/MyProfile/MyProfile";
import Sidebar from "../components/SideBar";
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      user: state => state.user
    })
  },
  components: {
    // FollowingFriends: FollowingFriends,
    MyProfile: MyProfile,
    Sidebar: Sidebar
  },
  methods: {
    activeMenu: className => {
      const activeList = document.querySelectorAll(".active");
      const btnWrapper = document.querySelector(`.${className}`);
      activeList.forEach(item => {
        item.classList.remove("active");
      });
      btnWrapper.classList.add("active");
    }
  },
  mounted() {
    if (!localStorage.getItem('user_token')) {
      this.$router.push('/')
    }
  }
};
</script>

<style scoped>
.profile-wrapper {
  margin-top: 1rem;
  width: 100%;
  min-height: 100vh;
  background-color: #f7f7f7;
  padding: 0 !important;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.profile-contents {
  padding: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
