import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "popper.js/dist/popper";
import "jquery/dist/jquery";
import "bootstrap/dist/js/bootstrap";
import "@fortawesome/fontawesome-free/js/all";
import { GoogleMap } from "./services";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import VueGtag from "vue-gtag-next";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const googleMap = new GoogleMap();
const firebaseConfig = {
  apiKey: "AIzaSyDXZz3RuIHVE6SevM3OOKD2zGi9jIw_RQM",
  authDomain: "matchday-website-6d9b9.firebaseapp.com",
  projectId: "matchday-website-6d9b9",
  storageBucket: "matchday-website-6d9b9.appspot.com",
  messagingSenderId: "877299147579",
  appId: "1:877299147579:web:a2ec38426ef3754e220d1e",
  measurementId: "G-6G48D5J2K0"
};

(async () => {
  await googleMap.connect();
  const app = await initializeApp(firebaseConfig);
  await getAnalytics(app);

  createApp(App)
    .use(store)
    .use(router)
    .use(VueGtag, {
      property: {
        id: "AW-677885569"
      }
    })
    .component("font-awesome-icon", FontAwesomeIcon)
    .provide("theme", "light")
    .mount("#app");
})();
