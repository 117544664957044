const state = {
  timeStart: null,
  timeEnd: null
};

const getters = {
  timeStart: state => state.timeStart,
  timeEnd: state => state.timeEnd
};

const actions = {
  setTimeStart({ commit }, data) {
    commit("SET_TIME_START", data);
  },
  setTimeEnd({ commit }, data) {
    commit("SET_TIME_END", data);
  }
};

const mutations = {
  SET_TIME_START(state, payload) {
    state.timeStart = payload;
  },
  SET_TIME_END(state, payload) {
    state.timeEnd = payload;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
