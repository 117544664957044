<template>
  <div
    class="modal fade"
    id="ChangeEmailModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">ที่อยูอีเมล</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <label for="">อีเมล</label>

          <input type="email" class="user-input" v-model="email" />
        </div>
        <div class="modal-footer">
          <button
            type="button"
            id="close"
            class="btn btn-secondary"
            data-dismiss="modal"
          >
            ยกเลิก
          </button>
          <button
            type="button"
            class="btn btn-primary"
            data-dismiss="modal"
            @click="edit_email()"
          >
            บันทึก
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  data: () => {
    return {};
  },
  computed: {
    ...mapGetters({
      user_detail: "user/GET_USER"
    }),
    email: {
      get() {
        return this.user_detail.email;
      },
      set(value) {
        this.SET_USER({ key: "email", value: value });
      }
    }
  },
  methods: {
    ...mapActions({
      upDateUser: "user/updateUser",
      getUser: "user/getUser"
    }),
    ...mapMutations({
      SET_USER: "user/SET_ELEMENT"
    }),
    async edit_email() {
      await this.upDateUser({ email: this.email });
      await this.getUser();
    }
  }
};
</script>

<style scoped>
.modal-content {
  background-color: #f9f9f9 !important;
  width: auto;
  display: flex;
}

.modal-header {
  border: none !important;
  background-color: #f9f9f9;
  width: 90%;
  margin: 0rem auto;
}
.modal-body {
  background-color: #fff !important;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 90%;
  margin: 1rem auto;
}

.modal-footer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: none !important;
  background-color: #f9f9f9;
}

.user-input {
  width: 100%;
}

.btn-secondary {
  background-color: transparent !important;
  border: 0px !important;
  color: #e21e1e;
  padding: 0.5rem 2rem;
}

.btn-primary {
  border-radius: 0.2rem;
  background-color: #e21e1e;
  border: 1px solid transparent;
  padding: 0.5rem 2rem;
}

.close {
  font-size: 2rem;
  font-weight: 400;
}
</style>
