import styled from "vue3-styled-components";

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1500;
  overflow: auto;
`;

const ModalDialog = styled.div`
  position: relative;
  max-width: 95%;
  margin: 2rem auto;
  @media (min-width: 576px) {
    max-width: ${$props => $props.maxWidth};
  }
`;

const ModalContent = styled.div`
  width: 100%;
  padding: 0.5rem;
  background-color: #ffffff;
  border-radius: 7px;
  overflow: hidden;
`;

export const ModalElement = {
  ModalWrapper,
  ModalDialog,
  ModalContent
};
