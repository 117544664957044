import UserProvider from "@/resources/user_provider";

const userService = new UserProvider();

const state = {
  user: {}
};

const getters = {
  GET_USER: state => state.user
};

const actions = {
  async getUser({ commit }) {
    const default_avatar = 'https://cdn.discordapp.com/attachments/780994260471775272/989465668854374420/player.png'
    
    var data = await userService.getUser();
    data.avatar = data.avatar? data.avatar:default_avatar

    commit("SET_USER", data);
  },
  async updateUser(commit, params) {
    await userService.EditUser(params);
    commit;
  }
};

const mutations = {
  SET_USER(state, data) {
    state.user = data;
  },
  SET_ELEMENT(state, data) {
    state.user[data.key] = data.value;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
