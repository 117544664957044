import styled, { ThemeProvider } from "vue3-styled-components";

const Divider = styled.div`
  width: ${$props => ($props.full == "" ? "100%" : "90%")};
  margin: 1rem auto;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.3);
`;

const PillButton = styled.button`
  appearance: none;
  border: 1px solid transparent;
  border-radius: 2rem;
  padding: 0.3rem 1rem;
  margin: 0.3rem 0.3rem;
  color: ${$props => ($props.theme == "light" ? "var(--text-color)" : "#fff")};
  color: ${$props => $props.color};
  background: #e21e1e;
  box-shadow: 0px 1px 1px #00000029;
  background-color: ${$props => $props.bg};
  border-color: ${$props => $props.bd};
  transition: filter 0.25s ease;

  &:hover {
    filter: brightness(0.95);
  }
`;

const gridColumn = number => {
  let col = "";
  for (let i = 1; i <= number; i++) {
    col += "auto ";
  }
  return col;
};

const Grid = styled.div`
  display: grid;

  grid-template-columns: ${$props => gridColumn($props.col)};
`;

const GridColumn = styled.div`
  padding: ${$props => $props.gapY}rem ${$props => $props.gapX}rem;
`;

const Checkbox = styled.input`
  width: 1em;
  height: 1em;
  margin: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  transition: border-color box-shadow background-color;
  transition-duration: 0.25s;
  transition-timing-function: ease;
  &:focus {
    outline: none;
    border-color: var(--primary);
    box-shadow: 0px 1px 1px #00000029;
  }
  &:checked {
    background-color: var(--primary);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
  }
`;

const TextButton = styled.a`
  cursor: pointer;
  text-decoration: none;
  color: ${$props => $props.color};
  &:hover {
    text-decoration: underline;
  }
`;

const Spinner = styled.div`
  border: 0.2rem solid ${$props => $props.color};
  border-color: transparent ${$props => $props.color} ${$props => $props.color}
    ${$props => $props.color};
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  animation: spinner 2s ease infinite;
`;

const Icon = styled.div`
  width: ${$props => $props.size};
  height: ${$props => $props.size};
  border-radius: ${$props => $props.radius};
  background-color: ${$props => $props.bgColor};
  border: ${$props => $props.bdWidth} solid ${$props => $props.bdColor};
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;
`;
// const btnTheme = {
//   primary: { from: "#d60326", to: "#ee4a31" },
//   warning: { from: "var(--warning)", to: "rgb(242, 222, 61)" },
// };
const RoundColorButton = styled.button`
  background-color: ${$props => $props.color}
  width: 100%;
  display: block;
  appearance: none;
  padding: 0.5rem 1.3rem;
  color: #fff;
  cursor: pointer;
  border: none;
  border-radius: 1.5rem;
  transition: filter 0.25s ease-out;
  &:hover {
    filter: brightness(0.9);
  }
  &.disabled {
    opacity: 0.7;
  }
`;
const GradientButton = styled.button`
  background-color: ${$props => $props.color}
  width: 100%;
  display: block;
  appearance: none;
  padding: 0.5rem 1.3rem;
  color: #fff;
  cursor: pointer;
  border: none;
  border-radius: 1.5rem;
  transition: filter 0.25s ease-out;
  &:hover {
    filter: brightness(0.9);
  }
  &.disabled {
    opacity: 0.7;
  }
`;
const StepNumber = styled.span`
  width: 1.5rem;
  height: 1.5rem;
  font-size: 8px;
  color: white;
  border-radius: 50%;
  background-color: var(--gray);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  &.active {
    background-color: #e21e1e;
  }
`;
const StepText = styled.div`
  font-size: 0.7rem;
  color: gray;
  text-align: center;
  &.active {
    color: #e21e1e;
  }
`;
const StepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const StepLine = styled.div`
  margin: 0.5rem -1.3rem 0 -1.3rem;
  background-color: var(--gray);
  height: 0.5rem;
  width: 12rem;
  &.active {
    background-color: #e21e1e;
  }
`;

const ResultProviderFeature = styled.div`
  display: inline-block;
  border: 2px solid ${$props => $props.bdColor};
  background-color: ${$props => $props.bgColor};
  color: ${$props => $props.fontColor};
  border-radius: 1.5rem;
  font-size: 0.7rem;
  padding: 0.2rem 0.5rem;
  margin: auto;
`;

const SelectInput = styled.select`
  appearance: none;
  background: none;
  background-color: transparent;
  padding: 0.3 0.5rem;
  margin: 0 0.3rem;
  border: 1px solid transparent;
  width: 100%;
  transition: border-color 0.2s ease;
  &:focus {
    outline: none;
    border-bottom-color: var(--primary);
  }
`;

const FilterButton = styled.button`
  appearance: none;
  padding: 0.2rem 1rem;
  margin: 0.3rem;
  color: #2b2b2b;
  cursor: pointer;
  border: 1px solid #dddddd;
  border-radius: 1.5rem;
  display: inline;
  background-color: #f5f6f7;
  transition: filter 0.25s ease-out;
  &:hover {
    filter: brightness(0.9);
  }
`;

export const CommonElement = {
  FilterButton,
  Divider,
  PillButton,
  ThemeProvider,
  Grid,
  GridColumn,
  Checkbox,
  TextButton,
  Spinner,
  Icon,
  RoundColorButton,
  GradientButton,
  StepNumber,
  StepText,
  StepWrapper,
  StepLine,
  ResultProviderFeature,
  SelectInput
};
