<template>
  <Modal ref="modal" :class="loging ? 'loging' : ''" maxWidth="500px">
    <div class="modal-header  pl-4 pr-4">
      <h5 class="modal-title">เข้าสู่ระบบ</h5>
      <Spinner color="var(--gray)" v-if="loging"></Spinner>
    </div>
    <div class="modal-body pl-4 pr-4">
      <AuthTab>
        <AuthTabItem
          :class="activeCheck('EmailLogin')"
          @click="setActive('EmailLogin')"
        >
          <AuthTabItemBody>อีเมล</AuthTabItemBody>
        </AuthTabItem>
        <AuthTabItem
          :class="activeCheck('PhoneLogin')"
          @click="setActive('PhoneLogin')"
        >
          <AuthTabItemBody>มือถือ</AuthTabItemBody>
        </AuthTabItem>
      </AuthTab>
      <div class="pt-3 ">
        <div class="alert alert-primary" role="alert" v-if="error">
          <strong>ขออภัย ไม่พบผู้ใช้หรือรหัสผ่านไม่ถูกต้อง</strong>
        </div>
        <component :is="active" @login="login"></component>
        <div class="mt-1 mb-1">
          <AuthDivider style="color: rgba(0,0,0,0.3)"
            ><AuthDividerLine style="background-color: rgba(0,0,0,0.3)"></AuthDividerLine> หรือ
            <AuthDividerLine style="background-color: rgba(0,0,0,0.3)"></AuthDividerLine
          ></AuthDivider>
        </div>
        <AuthButton
          style="padding: none; text-align: center"
          type="full"
          bg="#fff"
          bd="rgba(0,0,0,0)"
          theme="light"
          @click="$emit('fbLogin')"
          ><img
            src="https://prod-media-as1.s3.ap-southeast-1.amazonaws.com/miscellaneous/Image_35_2x_xgmt1u.png"
            alt="Facebook Logo"
          />
          ลงชื่อเข้าใช้ด้วยบัญชี Facebook
        </AuthButton>
        <AuthButton
          style="padding: none; text-align: center"
          type="full"
          bg="#fff"
          bd="rgba(0,0,0,0)"
          theme="light"
          @click="ggSignIn()"
          ><img src="https://prod-media-as1.s3.ap-southeast-1.amazonaws.com/miscellaneous/search_cu4whi.svg"/>
          ลงชื่อเข้าใช้ด้วยบัญชี Google
        </AuthButton>
      </div>
    </div>
    <div class="modal-footer p-0 pb-3">
      <Divider full></Divider>
      <div
        class="w-100 pl-3 pr-3 d-flex justify-content-between align-items-center"
      >
        <span class="text-muted">ยังไม่มีบัญชีผู้ใช้?</span>
        <AuthButton
          theme="light"
          bd="#e21e1e"
          bg="#fff"
          color="#e21e1e"
          @click="openRegister"
        >
          สร้างบัญชีผู้ใช้
        </AuthButton>
      </div>
    </div>
  </Modal>
</template>

<script>
import EmailLogin from "./EmailLogin.vue";
import PhoneLogin from "./PhoneLogin.vue";
import { AuthElement, CommonElement } from "../styled";
import Modal from "../Modal.vue";
import UserProvider from "../../resources/user_provider";
import { GoogleOAuth } from "../../services";
export default {
  components: {
    EmailLogin,
    PhoneLogin,
    Modal,
    ...AuthElement,
    ...CommonElement
  },
  data() {
    return {
      active: "EmailLogin",
      loging: false,
      error: false
    };
  },
  emits: ["openRegister", "loggedIn", "fbLogin", "googleLogin"],
  methods: {
    setActive(tab) {
      this.active = tab;
    },
    gUser(googleUser) {
      this.$emit("googleLogin", googleUser);
    },
    gError(error) {
      console.log(error);
    },
    async ggSignIn() {
      const gAuth = new GoogleOAuth();
      await gAuth.connect().then(instance => {
        instance.load("auth2", () => {
          let auth2 = gAuth.init();
          auth2
            .signIn()
            .then(GoogleUser => {
              // on success do something
              this.gUser(GoogleUser);
            })
            .catch(error => {
              this.gError(error);
            });
        });
      });
    },
    async show() {
      this.$refs.modal.show();
      /* const gAuth = new GoogleOAuth();
      await gAuth.connect().then(instance => {
        instance.load("auth2", () => {
          let auth2 = gAuth.init();
          auth2.attachClickHandler(
            document.getElementById("g-auth"),
            {},
            googleUser => this.gUser(googleUser),
            error => this.gError(error)
          );
        });
      }); */
    },
    hide() {
      this.$refs.modal.hide();
    },
    openRegister() {
      this.$refs.modal.hide();
      this.$emit("openRegister");
    },
    activeCheck(tab) {
      return tab == this.active ? "active" : "";
    },
    async login(data) {
      const userProvider = new UserProvider();
      this.loging = true;
      const res = await userProvider.login(data).catch(err => console.log(err));
      this.loging = false;
      if (res) {
        localStorage.setItem("user_token", res.token);
        this.$emit("loggedIn");
        this.$refs.modal.hide();
      } else {
        this.error = true;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
  .loging {
    pointer-events: none;
    filter: grayscale(1);
  }
</style>
