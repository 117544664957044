<template>
  <template v-if="keepAlive">
    <transition name="modal">
      <keep-alive>
        <ModalWrapper
          v-if="showing"
          ref="wrapper"
          @click="OutsideClick($event.target)"
        >
          <ModalDialog ref="dialog" class="modal-dialog">
            <ModalContent class="mdcT">
              <slot></slot>
            </ModalContent>
          </ModalDialog>
        </ModalWrapper>
      </keep-alive>
    </transition>
  </template>
  <template v-else>
    <transition name="modal">
      <ModalWrapper
        v-if="showing"
        ref="wrapper"
        @click="OutsideClick($event.target)"
      >
        <ModalDialog ref="dialog" v-bind:maxWidth="maxWidth">
          <ModalContent class="mdcT">
            <slot></slot>
          </ModalContent>
        </ModalDialog>
      </ModalWrapper>
    </transition>
  </template>
</template>

<script>
import { ModalElement } from "./styled";
export default {
  components: ModalElement,
  props: {
    keepAlive: Boolean,
    maxWidth: String
  },
  data() {
    return {
      showing: false
    };
  },
  emits: ["onhide", "onshow"],
  methods: {
    bodyOverride() {
      document.body.style.paddingRight = `${window.innerWidth -
        document.body.clientWidth}px`;
      document.body.style.overflow = "hidden";
    },
    removeBodyOverride() {
      document.body.style.paddingRight = "";
      document.body.style.overflow = "";
    },
    show() {
      this.showing = true;
      this.$emit("onshow");
    },
    hide() {
      this.showing = false;
      this.$emit("onhide");
    },
    OutsideClick(target) {
      if (this.$refs.dialog == null) {
        return;
      }

      if (
        !(
          target == this.$refs.dialog.$el ||
          this.$refs.dialog.$el.contains(target)
        )
      ) {
        this.hide();
      }
    }
  },
  watch: {
    showing(isShow) {
      isShow ? this.bodyOverride() : this.removeBodyOverride();
    }
  }
};
</script>
<style lang="scss" scoped>
@keyframes modal-fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes modal-bonce {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
.modal-enter-active {
  animation: modal-fade 0.25s linear;
  .mdcT {
    animation: modal-bonce 0.25s ease;
  }
}
.modal-leave-active {
  animation: modal-fade 0.25s reverse;
  .mdcT {
    animation: modal-bonce 0.25s reverse;
  }
}
.dalwrpr {
  box-sizing: content-box;
}
</style>
