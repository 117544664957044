<template>
  <div>
    <div class="modal-header  pl-4 pr-4">
      <h5 class="modal-title">สร้างบัญชีผู้ใช้</h5>
    </div>
    <div class="modal-body pl-4 pr-4">
      <AuthTab>
        <AuthTabItem
          :class="activeCheck('EmailRegister')"
          @click="setActive('EmailRegister')"
        >
          <AuthTabItemBody>อีเมล</AuthTabItemBody>
        </AuthTabItem>
        <AuthTabItem
          :class="activeCheck('PhoneRegister')"
          @click="setActive('PhoneRegister')"
        >
          <AuthTabItemBody>มือถือ</AuthTabItemBody>
        </AuthTabItem>
      </AuthTab>
      <div class="pt-3">
        <component :is="active" @submitUser="submitUser"></component>
      </div>
    </div>
  </div>
</template>

<script>
import { AuthElement, CommonElement } from "../styled";
import EmailRegister from "./EmailRegister.vue";
import PhoneRegister from "./PhoneRegister.vue";
export default {
  components: {
    ...AuthElement,
    ...CommonElement,
    EmailRegister,
    PhoneRegister
  },
  data() {
    return {
      active: "PhoneRegister"
    };
  },
  emits: ["setMode", "goOtp", "submitUser", "loading", "done", "error"],
  methods: {
    setActive(tab) {
      this.active = tab;
      this.$emit("setMode", tab);
    },
    activeCheck(tab) {
      return tab == this.active ? "active" : "";
    },
    submitUser(user) {
      this.$emit("submitUser", user);
      setTimeout(() => {
        this.$emit("goOtp");
      }, 100);
    }
  }
};
</script>

<style></style>
