import { google_app_id } from "../config";

export class GoogleOAuth {
  async connect() {
    const instance = await this.#getScript();
    return instance;
  }
  #getScript = () => {
    return new Promise(resolve => {
      if (window.gapi) {
        resolve(window.gapi);
      } else {
        (function(d, s, id) {
          var js,
            fjs = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) {
            return;
          }
          js = d.createElement(s);
          js.id = id;
          js.src = "https://apis.google.com/js/api.js";
          fjs.parentNode.insertBefore(js, fjs);
          js.addEventListener("load", () => {
            resolve(window.gapi);
          });
        })(document, "script", "google-signin-sdk");
      }
    });
  };
  init() {
    return window.gapi.auth2.init({
      client_id: google_app_id,
      discoveryDocs: ["https://people.googleapis.com/$discovery/rest"],
      // Request scopes in addition to 'profile' and 'email'
      scope: "profile email"
    });
  }
}
