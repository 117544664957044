import SportProvider from "@/resources/sportProvider_provider";
import { LocationService } from "../../services";
import PortalService from "../../resources/portal_service";

const sportService = new SportProvider();
const locationService = new LocationService();
const portal_service = new PortalService();

const state = {
  reviews: [],
  recommendedSports: [],
  addresses: [],
  provinceList: [],
  districtList: [],
  providers: [],
  sports: [],
  provider_reviews: []
};

const getters = {
  reviews: state => state.reviews,
  recommendedSports: state => state.recommendedSports,
  addresses: state => state.addresses,
  provinceList: state => state.addresses.map(({ province }) => province),
  districtList: state => province => {
    let filteredProvince = state.addresses.filter(address => {
      return address.province === province;
    });

    if (filteredProvince.length == 0) {
      return;
    }
    return filteredProvince[0].district;
  },
  providers: state => state.providers,
  sports: state => state.sports,
  provider_reviews: state => state.provider_reviews
};

const actions = {
  getReviews: ({ commit }) => {
    sportService.getReviews().then(res => {
      commit("SET_REVIEWS", res);
    });
  },
  getRecommendedSports: ({ commit }) => {
    sportService.getRecommendedSports().then(res => {
      commit("SET_RECOMMENDED_SPORT", res);
    });
  },
  getRecommendedPlaces({ commit }, payload) {
    return new Promise((resolve, reject) => {
      sportService.getRecommendedPlaces(payload).then(
        res => {
          commit("SET_RECOMMENDED_PLACES", res);
          resolve(res);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  getLocation({ commit }) {
    return new Promise(resolve => {
      locationService.accessLocation().then(
        res => {
          commit("SET_ALLOW_CURRENT_LOCATION", true);
          commit("SET_LOCATION", res);
          resolve(res);
        },
        error => {
          let default_latlng = {
            lat: 13.7241028,
            lng: 100.5281218
          };
          commit("SET_ALLOW_CURRENT_LOCATION", false);
          commit("SET_LOCATION", default_latlng);
          console.log(error);
          resolve(default_latlng);
        }
      );
    });
  },
  getAddresses: ({ commit }) => {
    return new Promise((resolve, reject) => {
      sportService.getAddresses().then(
        res => {
          commit("SET_ADDRESSES", res);
          resolve(res);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  getSportProviders: async ({ commit }) => {
    try {
      const providers = await sportService.getAll();
      commit("SET_PROVIDER_LIST", providers);
      return providers;
    } catch (error) {
      console.trace(error);
    }
  },
  async getAllSports({ commit }) {
    try {
      const sports = await portal_service.getAllSports();
      commit("SET_SPORT_LIST", sports);
      return sports;
    } catch (error) {
      console.trace(error);
    }
  },
  async getProviderReviews({ commit }) {
    try {
      const reviews = await sportService.getProviderReviews();
      commit("SET_PROVIDER_REVIEWS", reviews);
      return reviews;
    } catch (error) {
      console.trace(error);
    }
  }
};

const mutations = {
  SET_RECOMMENDED_SPORT(state, payload) {
    state.recommendedSports = payload;
  },
  SET_REVIEWS(state, payload) {
    state.reviews = payload;
  },
  SET_LOCATION(state, payload) {
    state.location = payload;
  },
  SET_ALLOW_CURRENT_LOCATION(state, payload) {
    state.allowCurrentLocation = payload;
  },
  SET_ADDRESSES(state, payload) {
    state.addresses = payload;
  },
  SET_PROVIDER_LIST(state, payload) {
    state.providers = payload;
  },
  SET_SPORT_LIST(state, payload) {
    state.sports = payload;
  },
  SET_PROVIDER_REVIEWS(state, payload) {
    state.provider_reviews = payload;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
