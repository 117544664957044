<template>
  <nav id="navbar" class="
            navbar
            fixed-top
            navbar-expand-lg navbar-dark
            main-nav
            justify-content-center
            row
            no-gutters
          ">
    <div class="col-lg-11 no-gutters row align-items-center ">
      <div class="col-xl row no-gutters align-items-center">
        <div class="col row no-gutters align-items-center ">

          <div
            :style="!isMobile() ? 'display:flex;justify-content: space-between;width:100%' : 'display:flex;justify-content:space-between;width:100%'">
            <div v-if="isMobile()">
              
            </div>
            <router-link class="navbar-brand" to="/" title="MatchDay">
              <!-- src="https://prod-media-as1.s3.ap-southeast-1.amazonaws.com/miscellaneous/Matchday_Logo_While_-01_2x_jzmgps.png" MatchDay -->
              <img src="@/assets/newlogo.png" width="95" height="45" alt=""
                loading="lazy" />
            </router-link>
            <!-- <div id="ver-div"></div> -->
            <button class="btn-red mt-2" @click="$router.push({path:'/voucher-deal'})" v-if="!isMobile()">
                <span>คูปองโปรโมชั่น</span>
              </button>
              <button class="btn-red mt-2" @click="$router.push({path:'/ais-deal'})" v-if="!isMobile()">
                <span>พิเศษลูกค้า AIS</span>
              </button>
              <button class="btn-red mt-2" @click="$router.push({path:'/news'})" v-if="!isMobile()">
                <span>บทความ</span>
              </button>
              <div style="width:700px"></div>
          
            <div style="display:flex;" v-if="!isMobile()">
              <button class="round-btn" @click="$router.push({path:'/partners'})">
                <span class="round-btn-txt">สำหรับเจ้าของสนาม</span>
              </button>
            
              <!-- <button class="btn-red" @click="$router.push({path:'/about'})">
                <span>เกี่ยวกับเรา</span>
              </button> -->
            </div>
            <button class="custom-toggler  navbar-toggler d-lg-none" type="button" data-toggle="collapse" v-if="isMobile()"
              data-target="#collapsibleNavId" aria-controls="collapsibleNavId" aria-expanded="false"
              aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>
          </div>
          <div class="collapse navbar-collapse" id="collapsibleNavId">
            <div class="row no-gutters justify-content-center  mt-3" v-if="isMobile()">
              <div class="col-xs-12  col-sm-12 mt-2" align="center">
                <button class="round-btn"  @click="$router.push({path:'/partners'})">
                  <span class="round-btn-txt">สำหรับเจ้าของสนาม</span>
                </button>
              </div>
              <div class="col-xs-12  col-sm-12 my-3" align="center"  @click="$router.push({path:'/voucher-deal'})">
                <button class="btn-red-mobile">
                  <span>คูปองโปรโมชั่น</span>
                </button>
              </div>
              <div class="col-xs-12  col-sm-12 my-3" align="center"  @click="$router.push({path:'/ais-deal'})">
                <button class="btn-red-mobile">
                  <span>พิเศษลูกค้า AIS</span>
                </button>
              </div>
              <!-- <div class="col-xs-12  col-sm-12 my-3" align="center"  @click="$router.push({path:'/about'})">
                <button class="btn-red-mobile">
                  <span>เกี่ยวกับเรา</span>
                </button>
              </div> -->
            </div>
            <!-- <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
               <li>
                <router-link
                  class="nav-link"
                  title="ค้นหาสนาม"
                  id="Home-link"
                  to="/"
                  >ค้นหาสนาม</router-link
                >
              </li>
              <li>
                <router-link
                  class="nav-link"
                  to="/about"
                  title="เกี่ยวกับเรา"
                  id="About-link"
                  >เกี่ยวกับเรา</router-link
                >
              </li>
              <li>
                <router-link
                  class="nav-link"
                  to="/partners"
                  title="เป็นพาร์ทเนอร์กับเรา"
                  id="Partners-link"
                  >เป็นพาร์ทเนอร์กับเรา
                </router-link>
              </li> -->
            <!-- <li>
                <router-link
                  class="nav-link"
                  to="/news"
                  title="Blog"
                  id="News-link"
                  >Blog</router-link
                >
              </li> -->
             <!-- <li>
                <router-link
                  class="nav-link"
                  to="/voucher-deal"
                  title="คูปองโปรโมชั่น"
                  id="Store-link"
                  >คูปองโปรโมชั่น</router-link
                >
              </li>
              <li>
                <router-link
                  class="nav-link"
                  to="/ais-deal"
                  title="พิเศษลูกค้า AIS"
                  id="Deal-ais-link"
                  >พิเศษลูกค้า AIS</router-link
                >
              </li>
            </ul> -->
            <div class="
                      col
                      row
                      no-gutters
                      align-items-center
                      justify-content-end
                      text-white
                    ">
              <template v-if="logged_in">
                <Spinner color="var(--white)" v-if="loading"></Spinner>
                <template v-else>
                  <span> {{ this.user_detail.fullname }} </span>
                  <div class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle text-light" href="#" id="navbarDropdown" role="button"
                      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <img :src="this.user_detail.avatar" class="avatar" />
                    </a>
                    <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                      <a class="dropdown-item" @click="goProfile()">บัญชีของฉัน</a>
                      <a class="dropdown-item" @click="goReserve()">การจองของฉัน</a>
                      <div class="dropdown-divider"></div>
                      <a class="dropdown-item text-danger" href="#" @click="logout">ออกจากระบบ</a>
                    </div>
                  </div>
                </template>
              </template>
              <template v-else>
                <PillButton style="display: none" bg="var(--primary)" theme="dark" @click="$refs.login.show()">
                  เข้าสู่ระบบ
                </PillButton>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Login ref="login" @loggedIn="updateUser" @openRegister="$refs.register.show()" @fbLogin="fbLogin"
      @googleLogin="googleLogin" />
    <Register ref="register" @openLogin="$refs.login.show()" @fbLogin="fbLogin" @googleLogin="googleLogin" />

    <Modal ref="phoneInputModal" @onhide="resetPhone" maxWidth="500px">
      <div class="modal-body pl-4 pr-4">
        <div class="d-flex justify-content-end">
          <Spinner color="var(--gray)" v-if="loading"></Spinner>
        </div>
        <div class="alert alert-primary" v-if="error" role="alert">
          <strong>{{ error_msg }}</strong>
        </div>
        <h4 class="text-center">กรอกเบอร์โทรศัพท์สำหรับการระบุตัวตน</h4>
        <AuthInput type="tel" v-model="phone" />
        <div>
          <AuthButton theme="dark" type="full" bg="#e21e1e" :class="accept ? '' : 'disabled'" @click="goSsoOtp">
            ดำเนินการต่อ</AuthButton>
        </div>
        <div class="d-flex">
          <div>
            <input class="auth-checkbox" v-model="accept" type="checkbox" />
          </div>
          <small>
            ท่านยอมรับ
            <AuthLink href="/terms" target="_blank">ข้อกำหนดการใช้งาน</AuthLink> และ
            <AuthLink href="/privacy-policy" target="_blank">นโยบายความเป็นส่วนตัว</AuthLink> ของ
            แมทช์เดย์ เมื่อดำเนินการต่อ
          </small>
        </div>
      </div>
    </Modal>
    <sso-otp ref="sotp" @done="saveSsoUser" :user="ssoUser" />
    <alert ref="alert" />
  </nav>
  <!-- <SecondaryHeader /> -->
</template>

<script>
import Login from "../Login/Login.vue";
import Register from "../Register/Register.vue";
// import SecondaryHeader from "../../components/headers/SecondaryHeader.vue"
import { CommonElement, AuthElement } from "../styled";
import { mapActions /*mapState,*/, mapGetters } from "vuex";
import UserProvider from "../../resources/user_provider";
import SsoOtp from "../SsoOtp.vue";
import Modal from "../Modal.vue";
import { AwsOtp } from "../../services";
import Alert from "../Alert.vue";
export default {
  components: {
    Login,
    Register,
    // SecondaryHeader,
    Modal,
    Alert,
    ...CommonElement,
    ...AuthElement,
    SsoOtp
  },
  data: () => ({
    logged_in: localStorage.getItem("user_token") ? true : false,
    loading: false,
    ssoUser: {
      given_name: null,
      family_name: null,
      firstname: null,
      lastname: null,
      email: null,
      phone: null,
      sub: null,
      avatar: null,
      picture: null,
      uid: null,
      otp_verified: null
    },
    phone: null,
    accept: false,
    error: false,
    error_msg: null
  }),
  methods: {
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    ...mapActions({
      getUser: "user/getUser",
    }),
    
    goProfile() {
      this.$router.push({
        path: "profile"
      });
    },
    goReserve() {
      this.$router.push({
        path: "my-reservation"
      });
    },
    async check_to_OTP(user) {
      const { phone_number } = user
      if (phone_number.toString().length == 10) {

      } else {
        this.$refs.login.hide()
        this.$refs.register.show()
        user.password = phone_number
        this.$refs.register.user = user
      }
    },
    updateUser() {
      this.logged_in = true;
      this.loading = true;
      this.getUser().then(() => {
        this.loading = false;
        this.check_to_OTP(this.user_detail)
      });
    },
    logout() {
      const user_provider = new UserProvider();
      user_provider.logout();
    },
    async googleLogin(googleUser) {
      const profile = googleUser.getBasicProfile();
      this.ssoUser.given_name = profile.getGivenName();
      this.ssoUser.family_name = profile.getFamilyName();
      this.ssoUser.email = profile.getEmail();
      this.ssoUser.avatar = profile.getImageUrl();
      this.ssoUser.uid = profile.getId();
      this.verify_user_google();
    },
    async verify_user_google() {
      const { token } = await this.getUser_google(this.ssoUser);
      localStorage.setItem("user_token", token);
      this.updateUser()
    },
    async getUser_google(user) {
      const userProvider = new UserProvider();
      return await userProvider.google_login(user)
    },
    async fbLogin() {
      const userProvider = new UserProvider();
      const user = await userProvider.facebookLogin();
      if (user.authResponse) {
        const info = await userProvider.getFacebookInfo(
          user.authResponse.userID
        );
        this.ssoUser.email = info.email;
        this.ssoUser.firstname = info.first_name;
        this.ssoUser.lastname = info.last_name;
        this.ssoUser.avatar = info.picture.data.url;
        this.ssoUser.uid = info.id;
        this.verify_user_facebook();
      }
    },
    async verify_user_facebook() {
      const { token } = await this.getUser_facebook(this.ssoUser);
      localStorage.setItem("user_token", token);
      this.updateUser()
    },
    async getUser_facebook(user) {
      const userProvider = new UserProvider();
      return await userProvider.facebook_login(user)
    },
    async checkExistEmail(value) {
      const userProvider = new UserProvider();
      const existEmail = await userProvider.checkExist(value, "email");
      if (existEmail) {
        this.$refs.alert.fire({
          title: "มีอีเมลนี้ในระบบอยู่แล้ว",
          message:
            "หากท่านต้องเชื่อมโยงบัญชี กรุณาลงชื่อเข้าใช้แล้วเชื่อมโยงบัญชีในการตั้งค่าแทน",
          confirmText: "ตกลง"
        });
        return true;
      } else {
        return false;
      }
    },
    async checkExistPhone(value) {
      this.error = false;
      this.error_msg = null;
      this.loading = true;
      const userProvider = new UserProvider();
      const existPhone = await userProvider.checkExist(value, "phone");
      this.loading = false;
      if (existPhone) {
        this.error = true;
        this.error_msg =
          "มีหมายเลขนี้ในระบบอยู่แล้ว หากท่านต้องเชื่อมโยงบัญชี กรุณาลงชื่อเข้าใช้แล้วเชื่อมโยงบัญชีในการตั้งค่าแทน";
        return true;
      } else {
        return false;
      }
    },
    phoneFormatError() {
      if (this.phone.length == 10) return;
      this.error = true;
      this.error_msg = "รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง";
      return true;
    },
    async goSsoOtp() {
      //Check phone length
      if (this.phoneFormatError()) return;
      //Check exist user
      this.ssoUser.phone = this.phone;
      const existPhone = await this.checkExistPhone(this.ssoUser.phone);
      if (existPhone) return;
      //Send otp
      this.loading = true;
      const aws_otp = new AwsOtp(this.ssoUser.phone, this.ssoUser.email);
      await aws_otp.create();
      this.$refs.sotp.show();
      this.loading = false;
    },
    async saveSsoUser() {
      this.loading = true;
      this.ssoUser.otp_verified = true;
      this.ssoUser.policy_accept = this.accept;
      const userProvider = new UserProvider();
      const res = await userProvider.ssoAccountRegister(this.ssoUser);
      this.$refs.sotp.hide();
      this.$refs.phoneInputModal.hide();
      if (res.status == "success") {
        localStorage.setItem("user_token", res.token.token);
        this.updateUser();
      }
      this.loading = false;
    },
    resetPhone() {
      this.error = false;
      this.error_msg = null;
      this.phone = null;
    },
    gotocoupon(){
       window.location.href = '/voucher-deal'
    },
    gotoais(){
       window.location.href = '/ais-deal'
    },
     gotopartner(){
       window.location.href = '/partners'
    },
     gotoaboutus(){
       window.location.href = '/about'
    }
  },
  created() {
    if (this.logged_in) {
      this.updateUser();
    }
  },
  computed: {
    ...mapGetters({
      user_detail: "user/GET_USER"
    })
    // ...mapState({
    //   user: state => state.user
    // })
  },
  mounted() {
    if (this.logged_in) {
      this.updateUser();
    }
  }
};
</script>

<style lang="scss" scoped>
.main-nav {
  background: #E13122;
  //  linear-gradient(180deg, #f50201 0%, #ee4a31 100%);
}

.avatar {
  width: 2.5rem;
  height: 2.5rem;
  object-fit: cover;
  border-radius: 50%;
  border: 0.1rem solid #fff;
  margin: 0 0.5rem;
}

.round-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 13px 16px;
  gap: 10px;
  margin: 5px;
  width: 228px;
  height: 40px;

  background: #121010;
  border-radius: 57px;

}

.round-btn-txt {
  font-family: 'Prompt';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;

  color: #FFFFFF;

}

.btn-red {
  width: 117px;
  height: 40px;
  margin: 5px;
  border-radius: 57px;
  background: #E13122;
  color: #FFFFFF;
  border: none;
}

.btn-red-mobile {
  width: 328px;
  height: 40px;
  margin: 5px;
  border-radius: 57px;
  border:#FFFFFF solid 1px;
  background: #E13122;
  color: #FFFFFF;
}

.custom-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255,1)' stroke-width='3' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}
</style>
