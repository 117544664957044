import { Auth } from "aws-amplify";
import { aws_config } from "../config";

Auth.configure(aws_config);

export class AwsOtp {
  #phone = null;
  #email = null;
  /**
   *
   * @param {String} phone
   * @param {String} email
   */
  constructor(phone, email) {
    let temp = phone.split("");
    let newPhone = "+66";
    if (temp.length > 9) {
      for (let index = 1; index < temp.length; index++) {
        newPhone += temp[index];
      }
    } else {
      newPhone += phone;
    }
    this.#phone = newPhone;
    this.#email = email;
  }

  async create() {
    try {
      const { user } = await Auth.signUp({
        username: this.#phone,
        password: "mmdd2021",
        attributes: { email: this.#email }
      });
      return { status: "success", user };
    } catch (error) {
      if (error.code == "UsernameExistsException") {
        try {
          const { user } = await Auth.resendSignUp(this.#phone);
          return { status: "success", user };
        } catch (err) {
          return { status: "error", error: err };
        }
      } else if (error.code == "InvalidParameterException") {
        return { status: "verified", user: undefined };
      } else {
        return { status: "error", error: error };
      }
    }
  }

  /**
   *
   * @param {String} code
   */
  async auth(code) {
    try {
      const res = await Auth.confirmSignUp(this.#phone, code);
      return (res == "SUCCESS") ? true : false;
    } catch (error) {
      const { code, message } = error
      if (code == "NotAuthorizedException" && message == "User cannot be confirmed. Current status is CONFIRMED") {
        return true
      } else {
        console.log("error confirming sign up", error);
      }
    }
  }
}
