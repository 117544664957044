import HttpRequest from "./http_request";
import { api_url } from "../config";

export default class SportProvider extends HttpRequest {
  constructor() {
    super(api_url);
  }

  async getProfile(id) {
    const { data } = await this.get("/public/get-sp/" + id);
    return data[0];
  }

  async getAll() {
    const { data } = await this.get("/public/get-sp/");
    return data;
  }

  async getFreeCourt(input) {
    const { data } = await this.create("/sp/free-courts", input);
    return data;
  }

  async getRecommendedPlaces(location) {
    const { data } = await this.get("/public/nearby-sp", location);
    return data;
  }

  async getRecommendedSports() {
    const { data } = await this.get("/public/recommended-sport");
    return data;
  }

  async search(input, method) {
    const { data } = await this.get("/search-provider", search_input_parser(input, method));
    // console.log(search_input_parser(input, method))
    return data;
  }

  async getReviews() {
    const { data } = await this.get("/public/review-sp");
    return data;
  }

  async getAddresses() {
    const { data } = await this.get("/public/all-location");
    return data;
  }

  async getCheapestPrice(input) {
    const { data } = await this.create(
      "/get-cheapest-provider-court-type",
      input
    );
    return data;
  }

  async getCalendarMatches(id, timeInput) {
    const { data } = await this.create(`/portal/my-matches/${id}`, timeInput);
    return data;
  }

  async createMatch(setting, token) {
    const auth = {
      Authorization: `Bearer ${token? token:localStorage.getItem("user_token")}`
    };
    this.setHeader(auth);
    const { data } = await this.create("/portal/create-match", setting);
    return data;
  }

  async getUser(userObject) {
    const { data } = await this.create(`/portal/getuser`, userObject);
    return data;
  }

  async getProviderReviews() {
    const { data } = await this.get("/public/get-sp-reviews");
    return data;
  }
}

function search_input_parser(input) {
  // console.log({method,input})
  if (!input.lat) delete input.lat
  if (!input.lng) delete input.lng
  if (input.sport == '') delete input.sport

  return input
}