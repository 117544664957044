<template>
  <transition name="modal">
    <ModalWrapper v-if="showing" ref="wrapper">
      <ModalDialog style="max-width:425px;" ref="dialog">
        <ModalContent class="mdcT">
          <div class="modal-body text-center">
            <h3>{{ options.title }}</h3>
            <p>{{ options.message }}</p>
          </div>
          <div class="modal-footer">
            <div class="d-flex w-100 justify-content-center pt-2">
              <template v-if="options.showConfirm">
                <button
                  type="button"
                  class="btn btn-secondary m-1"
                  v-bind:class="options.cancelClass"
                  :style="`background-color: ${options.cancelBg}`"
                  @click="close"
                  id="cancel-btn"
                >
                  {{ options.cancelText }}
                </button>
                <button
                  type="button"
                  class="btn btn-primary m-1"
                  v-bind:class="options.confirmClass"
                  :style="`background-color: ${options.confirmBg}`"
                  id="confirm-btn"
                >
                  {{ options.confirmText }}
                </button>
              </template>
              <template v-else>
                <button
                  class="btn btn-primary"
                  id="confirm-btn"
                  v-bind:class="options.confirmClass"
                  :style="`background-color: ${options.confirmBg}`"
                >
                  {{ options.confirmText }}
                </button>
              </template>
            </div>
          </div>
        </ModalContent>
      </ModalDialog>
    </ModalWrapper>
  </transition>
</template>

<script>
import { ModalElement } from "./styled";
export default {
  components: ModalElement,
  props: {
    keepAlive: Boolean
  },
  data() {
    return {
      showing: false,
      options: {
        title: "",
        message: "",
        showConfirm: "",
        confirmText: "",
        confirmClass: "",
        confirmBg: "",
        cancelText: "",
        cancelClass: "",
        cancelBg: ""
      }
    };
  },
  methods: {
    bodyOverride() {
      document.body.style.paddingRight = `${window.innerWidth -
        document.body.clientWidth}px`;
      document.body.style.overflow = "hidden";
    },
    removeBodyOverride() {
      document.body.style.paddingRight = "";
      document.body.style.overflow = "";
    },
    show() {
      this.showing = true;
    },
    hide() {
      this.showing = false;
    },
    async fire(
      options = {
        title: "",
        message: "",
        showConfirm: "",
        confirmText: "",
        confirmClass: "",
        confirmBg: "",
        cancelText: "",
        cancelClass: "",
        cancelBg: ""
      }
    ) {
      this.options = options;
      await this.show();
      return new Promise(resolve => {
        document.querySelectorAll("#confirm-btn").forEach(con => {
          con.addEventListener("click", () => {
            resolve(true);
            this.hide();
          });
        });
        document.querySelectorAll("#cancel-btn").forEach(can => {
          can.addEventListener("click", () => {
            resolve(false);
            this.hide();
          });
        });
      });
    }
  },
  watch: {
    showing(isShow) {
      isShow ? this.bodyOverride() : this.removeBodyOverride();
    }
  }
};
</script>
<style lang="scss" scoped>
@keyframes modal-fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes modal-bonce {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
.modal-enter-active {
  animation: modal-fade 0.25s linear;
  .mdcT {
    animation: modal-bonce 0.25s ease;
  }
}
.modal-leave-active {
  animation: modal-fade 0.25s reverse;
  .mdcT {
    animation: modal-bonce 0.25s reverse;
  }
}
.dalwrpr {
  box-sizing: content-box;
}
</style>
