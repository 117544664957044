import HttpRequest from "./http_request";
import { api_url } from "../config";
import { Facebook } from "../services";
class UserProvider extends HttpRequest {
  constructor() {
    super(api_url);
  }

  async getMatchData(id) {
    const auth = {
      Authorization: "Bearer " + localStorage.getItem("user_token")
    };
    this.setHeader(auth);
    const { data } = await this.get(`/matches/${id}`);
    return data;
  }

  async google_login(user) {
    const { data: { token } } = await this.create('/google-login', user);
    
    return token
  }

  async facebook_login(user) {
    const { data: { token } } = await this.create('/facebook-login', user);
    
    return token
  }

  async getUser() {
    const auth = {
      Authorization: "Bearer " + localStorage.getItem("user_token")
    };
    this.setHeader(auth);
    const { data } = await this.get("/my-profile");
    return data;
  }

  async reset_pass( email) {
    const { data } = await this.create('/reset-pass', { email: email });
    return data
  }

  async EditUser(param) {
    const auth = {
      Authorization: "Bearer " + localStorage.getItem("user_token")
    };
    this.setHeader(auth);
    const { data } = await this.update("/my-profile", param);
    return data;
  }

  async login(loginInfo = { username: "", password: "" }) {
    const { data } = await this.create("/login-user", loginInfo);
    return data;
  }

  async facebookLogin() {
    const fbService = new Facebook();
    await fbService.connect();
    const data = await fbService.login();
    return data;
  }

  async getFacebookInfo(id) {
    const fbService = new Facebook();
    await fbService.connect();
    const data = await fbService.getInfo(id);
    return data;
  }

  async ssoAccountRegister(info) {
    const { data } = await this.create("/sso-account-register", info);
    return data;
  }

  async ssoAccountLogin(uid) {
    const { data } = await this.create("/sso-account-login", { uid });
    return data;
  }

  async register(userInfo) {
    const { data } = await this.create("/register", userInfo);
    return data;
  }

  async checkExist(value, key) {
    const body = {};
    body[key] = value;
    const { data } = await this.create(`/check-${key}`, body);
    return data.status == "registered" ? true : false;
  }

  // TODO: move to payment_provider.js
  async checkPayment(id) {
    const auth = {
      Authorization: "Bearer " + localStorage.getItem("user_token")
    };
    this.setHeader(auth);
    const { data } = await this.get("/payment-check/" + id);
    return data;
  }

  // TODO: move to payment_provider.js
  async payment(body) {
    const { data } = await this.create("/payment", body);
    return data;
  }

  logout() {
    localStorage.clear();
    location.reload();
  }
}

export default UserProvider;
