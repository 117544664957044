export class LocationService {
  constructor() {}
  #location = {
    lat: 13.7241028,
    lng: 100.5281218
  }/* Default Location */

  #locationAccess = false;
  accessLocation() {
    this.#locationAccess = true;
    return new Promise((resolve, reject) => {
      if (navigator.permissions) {
        navigator.permissions.query({ name: "geolocation" }).then(res => {
          if (res.state == "denied") {
            reject("Access denied");
          } else {
            if (navigator.geolocation) {
              navigator.geolocation.getCurrentPosition(
                position => {
                  this.#location.lat = position.coords.latitude;
                  this.#location.lng = position.coords.longitude;
                  resolve(this.#location);
                },
                err => {
                  reject(err.message);
                },
                { maximumAge: 60000, timeout: 10000, enableHighAccuracy: false }
              );
            } else {
              reject("Can't access geolocation system");
            }
          }
        });
      } else {
        reject("Can't access geolocation system");
      }
    });
  }
  getLocation() {
    if (this.#locationAccess) {
      return this.#location;
    } else {
      this.#unsafeCall();
    }
  }
  #unsafeCall = function() {
    return this.#location
  };
}
