<template>
  <Navbar v-if="navbar" />
  <!-- <div :class="fullscreen? `views`:`container-fluid views`"> -->
  <div class="views" :style="!isMobile() ? '' : 'background:#E13122'">
    <router-view @request_fullscreen="fullscreen = true"></router-view>
   

  </div>
  <Footer v-if="!isMobile()" />
</template>

<script>
import Footer from "./components/layouts/Footer.vue";
import Navbar from "./components/layouts/Navbar.vue";
export default {
  components: { Navbar, Footer },
  data() {
    return {
      navbar: true,
      footer: true,
      fullscreen: false
    };
  },
  inject: ["theme"],
  methods: {
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    init_messenger() {
      window.fbAsyncInit = function() {
        FB.init({
          xfbml            : true,
          version          : 'v18.0'
        });
      };

      (function(d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s); js.id = id;
        js.src = 'https://connect.facebook.net/th_TH/sdk/xfbml.customerchat.js';
        fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'facebook-jssdk'));
      // var chatbox = document.getElementById('fb-customer-chat');
      // chatbox.setAttribute("page_id", "132518800773375");
      // chatbox.setAttribute("attribution", "biz_inbox");
    }
  },
  beforeCreate() {
    document.body.className = "override-body";
  },
  mounted() {
   // this.init_messenger()
  },
  watch: {
    $route: function (value) {
      this.navbar = value.matched[0].props.default
        ? value.matched[0].props.default.navbar
        : true;
      this.footer = value.matched[0].props.default
        ? value.matched[0].props.default.navbar
        : true;
      if (this.navbar) {
        document.body.style.paddingTop = "3.5rem";
      } else {
        document.body.style.paddingTop = "";
      }
      document.title = value.meta.title
        ? value.meta.title
        : "Matchday - ค้นหาและจองสนามกีฬาทุกประเภท เว็บไซต์และแอปพลิเคชั่นค้นหาสนามกีฬา พร้อมจองได้ทันที ทุกที่และใกล้ๆคุณ";
      window.scrollTo({ top: 0, left: 0 });
    }
  }
};
</script>

<style lang="scss">
@import "./style.scss";
</style>
