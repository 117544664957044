import styled from "vue3-styled-components";
const AuthLink = styled.a`
  color: #e21e1e;
  &:hover {
    color: #e21e1e;
    text-decoration: underline;
    filter: brightness(0.9);
  }
`;

const fullWidth = type =>
  type == "full" ? "width: 100%" : "width: max-content;";

const AuthButton = styled.button`
  appearance: none;
  border: 1px solid #e21e1e;
  border-radius: 5px;
  width: 100%;
  padding: 0.3rem;
  margin: 0.3rem 0;
  color: ${$props => ($props.theme == "light" ? "var(--text-color)" : "#fff")};
  color: ${$props => $props.color};
  background: #e21e1e;
  box-shadow: 0px 1px 1px #00000029;
  background-color: ${$props => $props.bg};
  ${$props => fullWidth($props.type)};
  border-color: ${$props => $props.bd};
  transition: filter opacity;
  transition-duration: 0.25s;
  transition-timing-function: ease;

  @media (min-width: 576px) {
    padding-left: ${$props =>
      $props.text == "center" || $props.text == undefined ? "1rem" : "5rem"};
    padding-right: ${$props =>
      $props.text == "center" || $props.text == undefined ? "1rem" : "0"};
    text-align: ${$props => ($props.text ? $props.text : "center")} !important;
  }

  &:hover {
    filter: brightness(0.9);
  }

  img {
    width: 1rem;
    height: 1rem;
    object-fit: cover;
  }
  &:disabled {
    pointer-events: none;
    opacity: 0.5;
  }
  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
`;

const AuthTab = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

const AuthTabItem = styled.a`
  flex: 1 auto;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.3);
  transition: color 0.25s ease;
  &:hover {
    color: rgba(0, 0, 0, 0.3);
    text-decoration: none;
    filter: brightness(0.9);
  }
  &.active {
    color: var(--primary);
    div {
      border-bottom-color: var(--primary);
    }
  }
`;

const AuthTabItemBody = styled.div`
  text-align: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  transition: border-bottom-color 0.25s ease;
`;

const AuthDivider = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
`;

const AuthDividerLine = styled.div`
  flex: 1 auto;
  margin: 0 0.5rem;
  height: 1px;
  background-color: var(--text-color);
`;

const AuthInput = styled.input`
  --webkit-appearance: none;
  appearance: none;
  width: 100%;
  padding: 0.3rem 1rem;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 0;
  transition: border-color box-shadow;
  transition-duration: 0.25s;
  transition-timing-function: ease;
  &:focus {
    outline: none;
    border-color: #e21e1e;
    box-shadow: 0px 1px 1px #00000029;
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    display: none;
  }
`;

const AuthCheckbox = styled.input`
  width: 1em;
  height: 1em;
  margin: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 0;
  --webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  transition: border-color box-shadow background-color;
  transition-duration: 0.25s;
  transition-timing-function: ease;
  &:focus {
    outline: none;
    border-color: #e21e1e;
    box-shadow: 0px 1px 1px #00000029;
  }
  &:checked {
    background-color: #e21e1e;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
  }
`;

const AuthOtpInput = styled.input`
  --webkikt-appearance: none;
  border-radius: 0;
  appearance: none;
  padding: 0.3rem 0.3rem;
  font-size: 1.5rem;
  width: 3rem;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.3);
  transition: border-color box-shadow;
  transition-duration: 0.25s;
  transition-timing-function: ease;
  &:focus {
    outline: none;
    border-color: #e21e1e;
    box-shadow: 0px 1px 1px #00000029;
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    display: none;
  }
`;

const AuthPhoneInputWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  border: 1px solid #707070;
`;

const AuthCountryPhoneCode = styled.input`
  --webkikt-appearance: none;
  border-radius: 0;
  appearance: none;
  padding: 0.3rem 0 rem;
  max-width: 3rem;
  border: none;
  cursor: pointer;
  transition: border-color box-shadow;
  transition-duration: 0.25s;
  transition-timing-function: ease;
  color: #989898;
  &:focus {
    outline: none;
    box-shadow: 0px 1px 1px #00000029;
  }
`;

const AuthCountryPhoneFront = styled.div`
  padding: 0.3rem 1rem;
  color: #989898;
  display: inline-block;
  width: max-content;
  img {
    width: 32px;
    height: 32px;
    object-fit: contain;
  }
  @media (min-width: 576px) {
    padding: 0.3rem 3rem;
  }
`;

const AuthPhoneInput = styled.input`
  --webkit-appearance: none;
  appearance: none;
  flex: 1 auto;
  padding: 0.3rem 1rem;
  border: none;
  border-radius: 0;
  background-color: #f2f2f2;
  transition: border-color box-shadow;
  transition-duration: 0.25s;
  transition-timing-function: ease;
  &:focus {
    outline: none;
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    display: none;
  }
`;

export const AuthElement = {
  AuthLink,
  AuthButton,
  AuthTab,
  AuthTabItem,
  AuthTabItemBody,
  AuthDivider,
  AuthDividerLine,
  AuthInput,
  AuthCheckbox,
  AuthPhoneInputWrapper,
  AuthCountryPhoneCode,
  AuthCountryPhoneFront,
  AuthPhoneInput,
  AuthOtpInput
};
