import { map_api_key } from "../config";
export class GoogleMap {
  map = null;
  wrapper = null;
  styles = [
    {
      featureType: "administrative",
      elementType: "geometry",
      stylers: [
        {
          visibility: "off"
        }
      ]
    },
    {
      featureType: "poi",
      stylers: [
        {
          visibility: "off"
        }
      ]
    },
    {
      featureType: "road",
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "off"
        }
      ]
    },
    {
      featureType: "transit",
      stylers: [
        {
          visibility: "off"
        }
      ]
    }
  ];
  /**
   *
   * @param {Element} map
   */
  constructor(wrapper) {
    this.wrapper = wrapper;
  }
  async connect() {
    const res = await this.#getScript();
    return res;
  }
  /**
   *
   * @param {Object} center
   * @param {Number} zoom
   * @param {Object} styles
   * @param {Object} options
   */
  init(center, zoom, options) {
    this.map = new window.google.maps.Map(this.wrapper, {
      center: center,
      zoom: zoom,
      styles: this.styles,
      ...options
    });
  }
  #getScript = () =>
    new Promise(resolve => {
      if (window.google) {
        resolve(window.google);
      } else {
        (function(d, s, id) {
          var js,
            fjs = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) {
            return;
          }
          js = d.createElement(s);
          js.id = id;
          js.src = `https://maps.googleapis.com/maps/api/js?key=${map_api_key}&region=TH&language=th`;
          // js.src = `https://maps.googleapis.com/maps/api/js?key=${map_api_key}&center=13.76325486263508,100.53834871631243&zoom=17&format=png&maptype=roadmap&style=feature:administrative%7Celement:geometry%7Cvisibility:off&style=feature:poi%7Cvisibility:off&style=feature:road%7Celement:labels.icon%7Cvisibility:off&style=feature:transit%7Cvisibility:off&size=480x360`;
          fjs.parentNode.insertBefore(js, fjs);
          js.addEventListener("load", () => {
            resolve(window.google);
          });
        })(document, "script", "google-map-sdk");
      }
    });
  /**
   *
   * @param {Object} position
   * @param {String} icon
   */
  addMarker(position, icon, title, id, options = {}) {
    return new window.google.maps.Marker({
      position: position,
      icon: icon,
      map: this.map,
      title: title,
      id: id,
      ...options
    });
  }

  findPlace(request = { query: "", fields: [] }) {
    const service = new window.google.maps.places.PlacesService(this.map);
    return new Promise(resolve => {
      service.findPlaceFromQuery(request, (results, status) => {
        if (
          status === window.google.maps.places.PlacesServiceStatus.OK &&
          results
        ) {
          resolve(results);
        }
      });
    });
  }

  findNearby(
    request = {
      location: {},
      radius: "",
      type: []
    }
  ) {
    const service = new window.google.maps.places.PlacesService(this.map);
    return new Promise(resolve => {
      service.nearbySearch(request, (results, status) => {
        if (
          status === window.google.maps.places.PlacesServiceStatus.OK &&
          results
        ) {
          resolve(results);
        }
      });
    });
  }
  panTo(position) {
    this.map.panTo(position);
  }
  setCenter(position) {
    this.map.setCenter(position);
  }
}
